import icons from '@fruits-chain/icons-react'

import { Menu } from 'antd'
import type { MenuProps } from 'antd/lib/menu'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { Link, matchRoutes, useLocation, useNavigate } from 'react-router-dom'
import { useDeepCompareEffect } from 'ahooks'
import useCommonLayout from '@/layout/useCommonLayout'

import styles from './style.module.less'
import useChildAppStore from './stores/useChildAppStore'
import useRoutesData, { CustomRouteConfig } from './useRoutesData'

import { getChildAppKeys } from './configData'
import useCurrentRoute from './useCurrentRoute'
/**
 * 从菜单中获取默认首页路由（第一个可选菜单项目）
 * @param menuList 菜单列表
 */
export const getHomepageUrl = (menuList: CustomRouteConfig[]) => {
  let menu: CustomRouteConfig | undefined = menuList?.[0]
  while (menu?.children?.length) {
    const child = childrenMenu(menu?.children)
    if (!(child?.length > 0)) {
      break
    }
    menu = child[0]
  }
  return menu?.path
}
const childrenMenu = (routes: CustomRouteConfig[]) => {
  return routes?.filter(v => !!v?.path && !!v?.meta?.title) || []
}
/**
 * 根据pathname匹配路由和菜单
 * @param pathname location.pathname
 * @returns 获取当前路由及菜单信息
 */
export const getCurrentRouteAndMenuInfo = (
  pathname: string,
  routes: CustomRouteConfig[],
): [CustomRouteConfig, CustomRouteConfig, string[]] => {
  let currentRoute: CustomRouteConfig = null
  let currentMenu: CustomRouteConfig = null
  let openKeys: string[] = []
  const matchRoutesList = matchRoutes(routes, { pathname })
  matchRoutesList?.forEach((v: any) => {
    if (v?.route?.meta?.title) {
      currentMenu = v?.route
      openKeys?.push(v?.route?.path)
    }
    currentRoute = v?.route
  })
  return [currentRoute, currentMenu, openKeys]
}
/**
 * 根据路由配置和权限数据获取菜单配置
 * @param routes 路由配置
 * @param authData 权限数据
 */
export const getMenuList = (routes: any[]): MenuProps['items'] => {
  const menus =
    routes
      ?.sort((a, b) => a?.order - b?.order)
      ?.filter(({ meta }) => {
        if (!meta?.title) {
          return false
        }
        return true
      })
      .map(item => {
        const children = getMenuList(item.children)

        let iconJsx = null
        const IconComponent = icons?.[item.meta?.icon]
        if (IconComponent) {
          iconJsx = <IconComponent />
        } else {
          if (
            typeof item.meta?.icon === 'string' &&
            item.meta?.icon?.startsWith('<svg')
          ) {
            iconJsx = (
              <div dangerouslySetInnerHTML={{ __html: item.meta?.icon }} />
            )
          } else {
            if (item.meta?.icon) {
              const Icon = icons?.[item.meta?.icon]
              iconJsx = Icon ? <Icon /> : null
            } else {
              iconJsx = null
            }
          }
        }

        return {
          label:
            children?.length > 0 ? (
              item?.meta?.title
            ) : (
              <Link to={Array.isArray(item.path) ? item.path[0] : item.path}>
                {item?.meta?.title}
              </Link>
            ),
          title: item?.meta?.title,
          key: item?.path,
          ...(iconJsx
            ? {
                icon: iconJsx,
              }
            : {}),
          // 递归处理的children
          ...(children?.length > 0 ? { children } : {}),
        }
      }) || null
  return menus
}
interface IProps extends MenuProps {}

const ChildAppSideMenu: React.FC<IProps> = ({ ...restProps }) => {
  const location = useLocation()
  const navigate = useNavigate()
  /** 子应用相关逻辑开始 */
  /** 根据配置和当前路由加载对应微前端的菜单路由并保存在useChildAppStore中 */
  useRoutesData()
  // useAppsData()
  const routes = useCurrentRoute()

  /** 子应用相关逻辑结束 */
  const { menuShown } = useCommonLayout()
  const [state, setState] = useState({
    currentRouteInfo: {
      matchedRoute: null,
      matchedMenu: null,
      menuOpenKeys: null,
    },
  })

  const menuList = useMemo(() => {
    return getMenuList(routes)
  }, [routes])
  // 根据 pathname 获取当前匹配到的路由配置、菜单配置、菜单展开的 key
  useEffect(() => {
    const [matchedRoute, matchedMenu, menuOpenKeys] =
      getCurrentRouteAndMenuInfo(location.pathname, routes)

    setState({
      currentRouteInfo: {
        matchedRoute,
        matchedMenu,
        menuOpenKeys,
      },
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, routes])
  useEffect(() => {
    if (
      getChildAppKeys()
        ?.map(v => `/${v}`)
        ?.includes(location.pathname)
    ) {
      const homeUrl = getHomepageUrl(routes)
      if (homeUrl?.startsWith(location.pathname)) {
        navigate(homeUrl)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, routes])
  const defaultOpenKeys = state?.currentRouteInfo?.menuOpenKeys
  useDeepCompareEffect(() => {
    if (menuShown) {
      setOpenKeys(defaultOpenKeys)
    }
  }, [defaultOpenKeys])
  const [openKeys, setOpenKeys] = useState(defaultOpenKeys)
  return menuList?.length > 0 ? (
    <Menu
      inlineIndent={16}
      theme="dark"
      mode="inline"
      {...(menuShown ? { openKeys: openKeys, onOpenChange: setOpenKeys } : {})}
      selectedKeys={
        state?.currentRouteInfo?.matchedMenu?.path
          ? [state?.currentRouteInfo?.matchedMenu?.path]
          : []
      }
      className={styles.sideMenu}
      items={menuList}
      {...restProps}
    />
  ) : null
}

export default memo(ChildAppSideMenu)
