import { message } from 'antd'
import I18N from '@/i18n'

let msgTime = 0
const msgs = {
  forbiddenMsg: I18N.get('utils.fetch.index.utilsFetchIndex1'),
  tokenInvalidMsg: I18N.get('utils.fetch.index.utilsFetchIndex2'),
  timeoutMsg: I18N.get('utils.fetch.index.utilsFetchIndex3'),
  errorMsg: I18N.get('utils.fetch.index.utilsFetchIndex4'),
  networkErrorMsg: I18N.get('utils.fetch.index.utilsFetchIndex5'),
}
// 错误提示
function handleNoCommonError(err, config = null) {
  // 请求配置了不提示时不提示
  if (config?.noErrorTip) {
    return false
  }
  // 弹框节流
  const now = Date.now()
  if (now - msgTime < 3000) {
    return false
  }
  msgTime = now
  // 提示
  if (err) {
    message.destroy()
    if (typeof err === 'string') {
      message.error(err)
    } else {
      message.error(JSON.stringify(err))
    }
  }
}
export { handleNoCommonError, msgs }
