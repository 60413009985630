import React from 'react'
import { Link } from 'react-router-dom'
import usePermissions from '@/stores/permissions'
import { useI18n } from '@/i18n/context'
import styles from './style.module.less'
const MicroAppsMenu: React.FC = () => {
  const { I18N } = useI18n()
  const { apps } = usePermissions()

  return (
    <div className={styles.menuPage}>
      <div className={styles.mainTitleWrap}>
        <div className={styles.mainTitle}>
          <h1 className={styles.mainTitleText}>
            {I18N.get('pages.menu.index.pagesMenuIndex1')}
          </h1>
        </div>
      </div>
      <div className={styles.pageContainer}>
        <div className={styles.appsWrap}>
          {apps?.map(v => {
            return (
              <div key={v?.name} className={styles.appItem}>
                <Link className={styles.appBox} to={`/${v?.code}`}>
                  <div className={styles.itemBody}>
                    {v?.iconUrl ? (
                      <img src={v?.iconUrl} alt="" className={styles.icon} />
                    ) : null}
                    <div className={styles.name}>{v?.name}</div>
                  </div>
                </Link>
              </div>
            )
          })}
          <div className={styles.appItem} />
          <div className={styles.appItem} />
          <div className={styles.appItem} />
          <div className={styles.appItem} />
        </div>
      </div>
    </div>
  )
}
export default MicroAppsMenu
