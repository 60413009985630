import { DownOutlined } from '@ant-design/icons'
import { useControllableValue } from 'ahooks'
import { Dropdown, Input } from 'antd'
import React, { FC, useEffect, useMemo, useState } from 'react'
import styles from './index.module.less'
export type SearchValueType = {
  searchType?: string
  keyword?: string
}
export interface SearchInputProps {
  value?: SearchValueType
  onChange?: (value: SearchValueType) => void
  defaultValue?: SearchValueType
}
const typeOptions = [
  { key: 'contentKeyword', label: '内容' },
  { key: 'titleKeyword', label: '标题' },
]
const SearchInput: FC<SearchInputProps> = props => {
  const [state, setState] = useControllableValue<SearchValueType>(props)
  const [input, setInput] = useState(state?.keyword || '')
  // useEffect(() => {
  //   if (!state?.searchType) {
  //     setState({ searchType: 'contentKeyword' })
  //   }
  // }, [setState, state])
  const searchAction = () => {
    setState({ ...state, keyword: input })
  }
  const searchType = useMemo(() => {
    return state?.searchType || 'contentKeyword'
  }, [state?.searchType])
  return (
    <div className={styles.searchInput}>
      <div className={styles.searchLeft}>
        <Dropdown
          menu={{
            onClick: val => {
              setState({ ...state, searchType: val?.key })
            },
            selectedKeys: [searchType],
            items: typeOptions,
            selectable: true,
          }}>
          <div className={styles.searchType}>
            <span className={styles.searchTypeTitle}>
              {typeOptions?.find(v => v?.key === searchType)?.label}
            </span>
            <DownOutlined className={styles.typeIcon} />
          </div>
        </Dropdown>
        <Input
          className={styles.input}
          placeholder="请输入关键词"
          onKeyDown={e => {
            if (e?.key === 'Enter') {
              searchAction()
            }
          }}
          allowClear
          value={input || ''}
          onChange={val => {
            setInput(val?.target?.value)
          }}
        />
      </div>
      <div className={styles.searchBtn} onClick={searchAction}>
        搜索
      </div>
    </div>
  )
}
export default SearchInput
