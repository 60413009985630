import type * as SchemaTypes from '../../../generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PageHelpManageQueryVariables = SchemaTypes.Exact<{
  input: SchemaTypes.PageHelpManageInput;
}>;


export type PageHelpManageQuery = { pageHelpManage?: { pageCurrent?: number | null, pageSize?: number | null, totalRecords?: number | null, records?: Array<{ appId?: Array<number | null> | null, content?: string | null, createTime?: any | null, enable?: number | null, file?: any | null, helpType?: SchemaTypes.HelpType | null, helpTypeLabel?: string | null, id?: number | null, title?: string | null, createBy?: { id?: number | null, name?: string | null } | null } | null> | null } | null };

export type GetHelpManageByIdQueryVariables = SchemaTypes.Exact<{
  input: SchemaTypes.Scalars['Int'];
}>;


export type GetHelpManageByIdQuery = { getHelpManageById?: { appId?: Array<number | null> | null, content?: string | null, createTime?: any | null, enable?: number | null, file?: any | null, helpType?: SchemaTypes.HelpType | null, helpTypeLabel?: string | null, id?: number | null, title?: string | null, createBy?: { id?: number | null, name?: string | null } | null } | null };

export type GetUpAndDownQueryVariables = SchemaTypes.Exact<{
  input: SchemaTypes.GetUpAndDownInput;
}>;


export type GetUpAndDownQuery = { getUpAndDown?: { down?: { appId?: Array<number | null> | null, content?: string | null, createTime?: any | null, enable?: number | null, file?: any | null, helpType?: SchemaTypes.HelpType | null, helpTypeLabel?: string | null, id?: number | null, title?: string | null, createBy?: { id?: number | null, name?: string | null } | null } | null, up?: { appId?: Array<number | null> | null, content?: string | null, createTime?: any | null, enable?: number | null, file?: any | null, helpType?: SchemaTypes.HelpType | null, helpTypeLabel?: string | null, id?: number | null, title?: string | null, createBy?: { id?: number | null, name?: string | null } | null } | null } | null };

export type GetHelpManageTypeQueryVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type GetHelpManageTypeQuery = { getHelpManageType?: Array<{ label?: string | null, value?: string | null } | null> | null };


export const PageHelpManageDocument = gql`
    query pageHelpManage($input: PageHelpManageInput!) {
  pageHelpManage(input: $input) {
    pageCurrent
    pageSize
    records {
      appId
      content
      createBy {
        id
        name
      }
      createTime
      enable
      file
      helpType
      helpTypeLabel
      id
      title
    }
    totalRecords
  }
}
    `;

/**
 * __usePageHelpManageQuery__
 *
 * To run a query within a React component, call `usePageHelpManageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageHelpManageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageHelpManageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePageHelpManageQuery(baseOptions: Apollo.QueryHookOptions<PageHelpManageQuery, PageHelpManageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageHelpManageQuery, PageHelpManageQueryVariables>(PageHelpManageDocument, options);
      }
export function usePageHelpManageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageHelpManageQuery, PageHelpManageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageHelpManageQuery, PageHelpManageQueryVariables>(PageHelpManageDocument, options);
        }
export type PageHelpManageQueryHookResult = ReturnType<typeof usePageHelpManageQuery>;
export type PageHelpManageLazyQueryHookResult = ReturnType<typeof usePageHelpManageLazyQuery>;
export type PageHelpManageQueryResult = Apollo.QueryResult<PageHelpManageQuery, PageHelpManageQueryVariables>;
export const GetHelpManageByIdDocument = gql`
    query getHelpManageById($input: Int!) {
  getHelpManageById(input: $input) {
    appId
    content
    createBy {
      id
      name
    }
    createTime
    enable
    file
    helpType
    helpTypeLabel
    id
    title
  }
}
    `;

/**
 * __useGetHelpManageByIdQuery__
 *
 * To run a query within a React component, call `useGetHelpManageByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHelpManageByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHelpManageByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetHelpManageByIdQuery(baseOptions: Apollo.QueryHookOptions<GetHelpManageByIdQuery, GetHelpManageByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHelpManageByIdQuery, GetHelpManageByIdQueryVariables>(GetHelpManageByIdDocument, options);
      }
export function useGetHelpManageByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHelpManageByIdQuery, GetHelpManageByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHelpManageByIdQuery, GetHelpManageByIdQueryVariables>(GetHelpManageByIdDocument, options);
        }
export type GetHelpManageByIdQueryHookResult = ReturnType<typeof useGetHelpManageByIdQuery>;
export type GetHelpManageByIdLazyQueryHookResult = ReturnType<typeof useGetHelpManageByIdLazyQuery>;
export type GetHelpManageByIdQueryResult = Apollo.QueryResult<GetHelpManageByIdQuery, GetHelpManageByIdQueryVariables>;
export const GetUpAndDownDocument = gql`
    query getUpAndDown($input: GetUpAndDownInput!) {
  getUpAndDown(input: $input) {
    down {
      appId
      content
      createBy {
        id
        name
      }
      createTime
      enable
      file
      helpType
      helpTypeLabel
      id
      title
    }
    up {
      appId
      content
      createBy {
        id
        name
      }
      createTime
      enable
      file
      helpType
      helpTypeLabel
      id
      title
    }
  }
}
    `;

/**
 * __useGetUpAndDownQuery__
 *
 * To run a query within a React component, call `useGetUpAndDownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpAndDownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpAndDownQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUpAndDownQuery(baseOptions: Apollo.QueryHookOptions<GetUpAndDownQuery, GetUpAndDownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUpAndDownQuery, GetUpAndDownQueryVariables>(GetUpAndDownDocument, options);
      }
export function useGetUpAndDownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUpAndDownQuery, GetUpAndDownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUpAndDownQuery, GetUpAndDownQueryVariables>(GetUpAndDownDocument, options);
        }
export type GetUpAndDownQueryHookResult = ReturnType<typeof useGetUpAndDownQuery>;
export type GetUpAndDownLazyQueryHookResult = ReturnType<typeof useGetUpAndDownLazyQuery>;
export type GetUpAndDownQueryResult = Apollo.QueryResult<GetUpAndDownQuery, GetUpAndDownQueryVariables>;
export const GetHelpManageTypeDocument = gql`
    query getHelpManageType {
  getHelpManageType {
    label
    value
  }
}
    `;

/**
 * __useGetHelpManageTypeQuery__
 *
 * To run a query within a React component, call `useGetHelpManageTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHelpManageTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHelpManageTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHelpManageTypeQuery(baseOptions?: Apollo.QueryHookOptions<GetHelpManageTypeQuery, GetHelpManageTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHelpManageTypeQuery, GetHelpManageTypeQueryVariables>(GetHelpManageTypeDocument, options);
      }
export function useGetHelpManageTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHelpManageTypeQuery, GetHelpManageTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHelpManageTypeQuery, GetHelpManageTypeQueryVariables>(GetHelpManageTypeDocument, options);
        }
export type GetHelpManageTypeQueryHookResult = ReturnType<typeof useGetHelpManageTypeQuery>;
export type GetHelpManageTypeLazyQueryHookResult = ReturnType<typeof useGetHelpManageTypeLazyQuery>;
export type GetHelpManageTypeQueryResult = Apollo.QueryResult<GetHelpManageTypeQuery, GetHelpManageTypeQueryVariables>;