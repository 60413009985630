import React, { useEffect, useMemo } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'

import { Link } from 'react-router-dom'
import { useCurrentApp } from '@/layout/useCurrentApp'
import { usePageHelpManageLazyQuery } from '@/graphql/gqls/help-center/__generated__/index.generated'

const HelpCenterEntry: React.FC = () => {
  const currentApp = useCurrentApp()
  const [queryHelpPage, { data }] = usePageHelpManageLazyQuery()
  useEffect(() => {
    if (currentApp?.appId) {
      queryHelpPage({
        variables: {
          input: {
            appId: [currentApp?.appId],
            pageCurrent: 1,
            pageSize: 1,
          },
        },
      })
    }
  }, [currentApp?.appId, queryHelpPage])
  const firstRecord = useMemo(() => {
    if (currentApp?.appId) {
      if (data?.pageHelpManage?.records?.length > 0) {
        return data?.pageHelpManage?.records?.[0]
      }
    }
    return false
  }, [currentApp?.appId, data?.pageHelpManage?.records])

  return firstRecord ? (
    <Link
      to={`/help-center?appId=${currentApp?.appId}&helpType=${firstRecord?.helpType}`}
      target="_blank">
      <QuestionCircleOutlined style={{ marginRight: '18px', color: '#fff' }} />
    </Link>
  ) : null
}

export default HelpCenterEntry
