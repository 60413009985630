export default {
  /**
   * 密码长度必须为 8 至 20 位;
   */
  utilsVerifyIndex1: '密码长度必须为 8 至 20 位',
  /**
   * 需要包含数字;
   */
  utilsVerifyIndex2: '需要包含数字',
  /**
   * 需要包含大写字母;
   */
  utilsVerifyIndex3: '需要包含大写字母',
  /**
   * 需要包含小写字母;
   */
  utilsVerifyIndex4: '需要包含小写字母',
  /**
   * 需要包含特殊字符;
   */
  utilsVerifyIndex5: '需要包含特殊字符',
  /**
   * 不能有空格;
   */
  utilsVerifyIndex6: '不能有空格',
  /**
   * 不能包含特殊字符;
   */
  utilsVerifyIndex7: '不能包含特殊字符',
}
