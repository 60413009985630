export default {
  langs: [
    { label: '中文', value: 'zh-CN' },
    { label: 'English', value: 'en-US' },
    { label: 'ภาษาไทย', value: 'th-TH' },
    { label: 'ViệtName', value: 'vi-VN' },
  ],
  layout: {
    components: {
      userInfo: {
        index: {
          layoutComponentsUserinfoIndex1: 'Chắc chắn thoát ra? ',
          layoutComponentsUserinfoIndex2: 'Xác nhận',
          layoutComponentsUserinfoIndex3: 'Hủy bỏ ',
          layoutComponentsUserinfoIndex4: 'thay đổi mật khẩu ',
          layoutComponentsUserinfoIndex5: 'Đăng xuất ',
        },
      },
    },
    index: { layoutIndex1: 'Hongjiu Fruit ', home: 'Trang đầu ' },
    whiteLayout: {
      index: {
        layoutWhitelayoutIndex1:
          '渝ICP备19002690号 - 7',
      },
    },
  },
  pages: {
    login: {
      index: {
        pagesLoginIndex1:
          'Chào mừng bạn đến với Nền tảng quản lý tích hợp của HongJiu Fruit',
        login: 'Đăng nhập ',
      },
    },
    menu: {
      index: { pagesMenuIndex1: 'Hoan nghênh sử dụng hệ thống Hongjiu Fruit ' },
    },
    'select-tenant': {
      index: {
        pagesSelecttenantIndex1: '选择租户/组织',
        pagesSelecttenantIndex2: 'Tổ chức ',
        pagesSelecttenantIndex3: 'Chọn tổ chức ',
        pagesSelecttenantIndex4: 'Xác nhận',
        pagesSelecttenantIndex5: 'Quay về đăng nhập',
        pagesSelecttenantIndex6: 'Người dùng ',
        pagesSelecttenantIndex7: 'Chọn người dùng ',
      },
    },
  },
  components: {
    business: {
      selectAppModal: {
        index: {
          components_businessSelectappmodalIndex1: 'Xác nhận',
          components_businessSelectappmodalIndex2: '切换系统',
          components_businessSelectappmodalIndex3: 'Chọn người dùng ',
          components_businessSelectappmodalIndex4: 'Chọn tổ chức ',
          components_businessSelectappmodalIndex5: '选择系统',
          components_businessSelectappmodalIndex6: '该组织下无系统权限~',
        },
      },
      pwdModification: {
        index: {
          components_businessPwdmodificationIndex1: 'Xác nhận',
          components_businessPwdmodificationIndex2: 'thay đổi mật khẩu ',
          components_businessPwdmodificationIndex3: 'Update successfully',
          components_businessPwdmodificationIndex4: '旧密码',
          components_businessPwdmodificationIndex5: 'Nhập mật khẩu ',
          components_businessPwdmodificationIndex6: 'Nhập mật khẩu ',
          components_businessPwdmodificationIndex7: 'Mật khẩu mới ',
          components_businessPwdmodificationIndex8: 'Nhập mật khẩu mới ',
          components_businessPwdmodificationIndex9: '新密码不能与旧密码一样',
          components_businessPwdmodificationIndex10: 'Đăng nhập mật khẩu mới ',
          components_businessPwdmodificationIndex11: 'Xác nhận mật khẩu mới ',
          components_businessPwdmodificationIndex12: '请确认密码',
          components_businessPwdmodificationIndex13: '密码不一致',
          components_businessPwdmodificationIndex14: '请确认密码',
        },
      },
    },
  },
  utils: {
    verify: {
      index: {
        utilsVerifyIndex1: '密码长度必须为 8 至 20 位',
        utilsVerifyIndex2: '需要包含数字',
        utilsVerifyIndex3: '需要包含大写字母',
        utilsVerifyIndex4: '需要包含小写字母',
        utilsVerifyIndex5: '需要包含特殊字符',
        utilsVerifyIndex6: '不能有空格',
        utilsVerifyIndex7: '不能包含特殊字符',
      },
    },
    fetch: {
      index: {
        utilsFetchIndex1: '拒绝访问',
        utilsFetchIndex2: '登录已过期，请重新登录',
        utilsFetchIndex3: '请求超时，请稍后再试',
        utilsFetchIndex4: '报告！服务器出了点小问题，稍后再试试...',
        utilsFetchIndex5: '您的网络出现问题，请检查网络重试',
      },
    },
  },
}
