import { useNavigate, useSearchParams } from 'react-router-dom'
import { Loading } from '@fruits-chain/react-bailu'
import React, { useEffect } from 'react'
import { MenuPath } from '@/router'

const Redirect = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const redirect = searchParams.get('redirect') || MenuPath
  useEffect(() => {
    navigate(redirect)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <Loading />
}
export default Redirect
