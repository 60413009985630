import { Hooks, Loading } from '@fruits-chain/react-bailu'
import React, { FC, useEffect, useMemo } from 'react'
import { Empty, Pagination } from 'antd'

import useUrlState from '@ahooksjs/use-url-state'
import { useBoolean } from 'ahooks'
import {
  PageHelpManageDocument,
  PageHelpManageQuery,
} from '@/graphql/gqls/help-center/__generated__/index.generated'
import { PageHelpManageInput } from '@/graphql/generated/types'
import { SearchValueType } from '../search-input'
import ListItem from './item'
import styles from './index.module.less'
const { useTablePagingGraphQL } = Hooks
export interface HelpListProps {}
const HelpList: FC<HelpListProps> = () => {
  const [urlParams, setUrlParams] = useUrlState<
    PageHelpManageInput & SearchValueType
  >()
  const [firstLoading, { set: setFirstLoading }] = useBoolean(true)

  const params = useMemo(() => {
    let res = {
      enable: 1,
    }
    if (urlParams?.appId) {
      res['appId'] = +urlParams?.appId
    }
    if (urlParams?.keyword) {
      res[urlParams?.searchType || 'contentKeyword'] = urlParams?.keyword
    }
    if (urlParams?.helpType) {
      res['helpType'] = urlParams?.helpType
    }

    res['pageSize'] = urlParams?.pageSize ? +urlParams?.pageSize : 10
    res['pageCurrent'] = urlParams?.pageCurrent ? +urlParams?.pageCurrent : 1

    return res
  }, [urlParams])
  const { tableProps } = useTablePagingGraphQL({
    gql: PageHelpManageDocument,
    gqlDataField: 'pageHelpManage',
    params: params,
    formValues2search: false,
    search2formValues: false,
    gqlBuildParams: (_, params) => {
      return { input: { ...params } }
    },
  })
  useEffect(() => {
    if (tableProps?.dataSource?.length > 0 && firstLoading) {
      setFirstLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableProps?.dataSource])

  return (
    <div>
      {tableProps?.dataSource?.length > 0 ? (
        <>
          {tableProps?.dataSource?.map(
            (
              v: PageHelpManageQuery['pageHelpManage']['records'][number],
              i,
            ) => {
              return <ListItem key={v?.id} data={v} />
            },
          )}
          {tableProps?.pagination?.total > 10 ? (
            <div className={styles.pagination}>
              <Pagination
                showTotal={true}
                {...tableProps?.pagination}
                current={+urlParams?.pageCurrent || 1}
                pageSize={+urlParams?.pageSize || 10}
                onChange={(pageCurrent, pageSize) => {
                  setUrlParams({ pageCurrent, pageSize })
                }}
              />
            </div>
          ) : null}
        </>
      ) : firstLoading ? (
        <Loading />
      ) : (
        <Empty className={styles.empty} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  )
}
export default HelpList
