import { useEffect } from 'react'
import useUrlState from '@ahooksjs/use-url-state'
import { useI18n } from '@/i18n/context'
import { useApi } from '@/service/api'

export type SsoLoginProps = {
  success: (token: string) => void
  authorizeHost: string
  response_type: string
  client_id: string
}
function useSsoLogin(props?: SsoLoginProps): {
  jumpLogin: () => void
  loading: boolean
} {
  // 返回code拿code换token
  const [state, setState] = useUrlState()
  let code = state?.code
  const { I18N } = useI18n()
  const { runAsync: refreshToken, loading } = useApi('refreshToken')

  useEffect(() => {
    if (code) {
      refreshToken({ token: code }).then(res => {
        // 登录成功走登录逻辑
        props?.success(res?.data)
      })
      setState({ code: undefined })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])
  const jumpLogin = () => {
    if (props.authorizeHost) {
      window.location.href = `${
        props.authorizeHost
      }/oauth2/authorize?response_type=${props.response_type}&client_id=${
        props.client_id
      }&redirect_uri=${encodeURIComponent(
        window.location.href,
      )}${`&lang=${I18N.currentLang}`}`
    }
  }
  return { jumpLogin, loading }
}

export default useSsoLogin
