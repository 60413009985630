import layout from './layout/_index'
import pages from './pages/_index'
import components from './components/_index'
import utils from './utils/_index'
export default {
  langs: [
    { label: '中文', value: 'zh-CN' },
    { label: 'English', value: 'en-US' },
    { label: 'ภาษาไทย', value: 'th-TH' },
    { label: 'ViệtName', value: 'vi-VN' },
  ],
  layout,
  pages,
  components,
  utils,
}
