import { request } from '@/utils/fetch'

/**
 * 获取app下载链接
 */
export const getAppDownloadUrl = ({ appType }) =>
  request({
    url: `/api/app/url/download/${appType}`,
    method: 'get',
  })
