/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Layout, Row, Space, Result, Button, Modal } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import throttle from 'lodash/throttle'
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'
import { ErrorBoundary } from '@fruits-chain/react-bailu'
import useUrlState from '@ahooksjs/use-url-state'
import { LangEnum } from '@/i18n/typing'
import imgs from '@/assets/images/index'
import usePermissions from '@/stores/permissions'
import LoadingPage from '@/components/loading'
import { useI18n } from '@/i18n/context'
import { MenuPath } from '@/router'
import { useIsMobile } from '@/hooks/useIsMobile'
import styles from './style.module.less'
import UserInfo from './components/user-info'
import LangSelect from './components/lang-select'
import SelectApp from './components/select-app'
import useRegisterApps from './useRegisterApps'
import useCommonLayout from './useCommonLayout'
import useRefreshToken from './useRefreshToken'
import AppDownload from './components/app-download'
import ChildAppSideMenu from './components/child-app-side-menu'
import useGetUserStatusInfo from './useGetUserStatusInfo'
import { useCurrentApp } from './useCurrentApp'
import { hasChildApp } from './components/child-app-side-menu/configData'
import HelpCenterEntry from './components/help-center-entry'

const { Header, Content, Sider } = Layout

const TITLE_MAP: Record<LangEnum, string> = {
  'en-US': 'Some error',
  'th-TH': 'Some error',
  'vi-VN': 'Some error',
  'zh-CN': '加载失败，请稍后再试~',
}

const RELOAD_MAP: Record<LangEnum, string> = {
  'en-US': 'Reload',
  'th-TH': 'Reload',
  'vi-VN': 'Reload',
  'zh-CN': '重新加载',
}

let index = 0

const updateBrowser = throttle(() => {
  if (index === 0) {
    Modal.warning({
      title: '浏览器版本过低',
      content: '请使用最新的 Chrome、Edge 浏览器访问',
      afterClose: () => {
        index--
      },
    })
    index++
  }
}, 500)

const LayoutPage: React.FC = () => {
  // 控制不显示header和sideMenu
  const [urlState] = useUrlState<{ onlyShowBody?: string }>()
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const { appLoading } = usePermissions()
  const { menuShown, renderHeaderLeft, renderHeaderRight } = useCommonLayout()
  const { setState } = useCommonLayout
  const CollapseIcon = menuShown ? MenuFoldOutlined : MenuUnfoldOutlined
  const toggleMenuShown = () => {
    setState({ menuShown: !menuShown })
  }
  useGetUserStatusInfo()
  // 重新进入应用刷新token
  useRefreshToken()

  // 根据权限注册子应用
  useRegisterApps()

  const currentApp = useCurrentApp()

  const hasSideMenu = useMemo(() => {
    return !urlState?.onlyShowBody && hasChildApp(currentApp?.code)
  }, [currentApp?.code])
  const { I18N } = useI18n()
  const errorTitle = TITLE_MAP[I18N.currentLang]
  const errorReload = RELOAD_MAP[I18N.currentLang]

  const renderError = useCallback(
    ({
      name,
      message,
      onReset,
    }: {
      name: string
      message: string
      onReset: () => void
    }) => {
      if (message.indexOf('is not a function') >= 0) {
        updateBrowser()
      }

      return (
        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Result
            status="warning"
            title={errorTitle}
            subTitle={`${name}\n${message}`}
            extra={
              <Button type="primary" onClick={onReset}>
                {errorReload}
              </Button>
            }
          />
        </div>
      )
    },
    [errorTitle, errorReload],
  )

  return (
    <Layout className={styles.mainLayout}>
      {urlState?.onlyShowBody ? null : (
        <Header className={styles.header}>
          <Row align="middle" justify="space-between">
            <div className={styles.leftWrap}>
              <div
                className={styles.logoBox}
                onClick={() => {
                  navigate(MenuPath)
                }}>
                <img src={imgs.AppNewLogo} alt="" className={styles.logo} />
                <h3 className={styles.appName}>
                  {I18N.get('layout.index.layoutIndex1')}
                </h3>
              </div>
              <Divider type="vertical" className={styles.divider} />
              <SelectApp />
              {renderHeaderLeft?.()}
              <CollapseIcon
                className={styles.collapseIcon}
                onClick={toggleMenuShown}
              />
            </div>
            <Space className={styles.operationWrap}>
              {renderHeaderRight?.()}
              <HelpCenterEntry />
              <AppDownload />
              <UserInfo />
              <Divider type="vertical" className={styles.divider} />
              <LangSelect />
            </Space>
          </Row>
        </Header>
      )}
      <Layout>
        {/* 左侧菜单栏 */}
        {hasSideMenu ? (
          <Sider
            collapsed={!menuShown}
            trigger={null}
            collapsible
            width={218}
            collapsedWidth={isMobile ? 40 : undefined}>
            <ChildAppSideMenu />
          </Sider>
        ) : null}

        <Layout>
          <Content>
            <ErrorBoundary renderError={renderError}>
              <Outlet />
              {appLoading ? (
                <div className={styles.loading}>
                  <LoadingPage />
                </div>
              ) : null}
            </ErrorBoundary>
            <div id="micro-app" className={styles.microApp} />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}
export default LayoutPage
