export default {
  langs: [
    { label: '中文', value: 'zh-CN' },
    { label: 'English', value: 'en-US' },
    { label: 'ภาษาไทย', value: 'th-TH' },
    { label: 'ViệtName', value: 'vi-VN' },
  ],
  layout: {
    components: {
      userInfo: {
        index: {
          layoutComponentsUserinfoIndex1: 'ต้องการออกจากระบบ',
          layoutComponentsUserinfoIndex2: 'ยืนยัน',
          layoutComponentsUserinfoIndex3: 'ยกเลิก',
          layoutComponentsUserinfoIndex4: 'แก้ไขรหัสผ่าน',
          layoutComponentsUserinfoIndex5: 'ออกจากระบบ',
        },
      },
    },
    index: {
      layoutIndex1: 'แพลตฟอร์มการจัดการซัพพลายเชนระดับโลกของหงจิ่วฟรุต',
      home: 'หน้าหลัก',
    },
    whiteLayout: {
      index: {
        layoutWhitelayoutIndex1:
          '渝ICP备19002690号 - 7',
      },
    },
  },
  pages: {
    login: {
      index: {
        pagesLoginIndex1:
          'ยินดีต้อนรับสู่แพลตฟอร์มการจัดการผลไม้แบบบูรณาการหงษ์จิ่ว',
        login: 'เข้าสู่ระบบ',
      },
    },
    menu: {
      index: {
        pagesMenuIndex1:
          'ยินดีต้อนรับการใช้บริการแพลตฟอร์มการจัดการซัพพลายเชนระดับโลกของหงษ์จิ่วฟรุต',
      },
    },
    'select-tenant': {
      index: {
        pagesSelecttenantIndex1: 'เลือกบัญชี/กลุ่ม/หน่วยงาน',
        pagesSelecttenantIndex2: 'องค์กร',
        pagesSelecttenantIndex3: 'เลือกองค์กร',
        pagesSelecttenantIndex4: 'ยืนยัน',
        pagesSelecttenantIndex5: 'กลับไปเข้าสู่ระบบ',
        pagesSelecttenantIndex6: 'ผู้เช้า',
        pagesSelecttenantIndex7: 'เลือกผู้เช้า',
      },
    },
  },
  components: {
    business: {
      selectAppModal: {
        index: {
          components_businessSelectappmodalIndex1: 'ยืนยัน',
          components_businessSelectappmodalIndex2: 'ระบบสวิตช์',
          components_businessSelectappmodalIndex3: 'เลือกผู้เช้า',
          components_businessSelectappmodalIndex4: 'เลือกองค์กร',
          components_businessSelectappmodalIndex5: 'เลือกระบบ',
          components_businessSelectappmodalIndex6:
            'ไม่มีสิทธิ์ของระบบภายใต้องค์กรนี้',
        },
      },
      pwdModification: {
        index: {
          components_businessPwdmodificationIndex1: 'ยืนยัน',
          components_businessPwdmodificationIndex2: 'แก้ไขรหัสผ่าน',
          components_businessPwdmodificationIndex3: 'แก้ไขเรียบร้อยแล้ว',
          components_businessPwdmodificationIndex4: 'รหัสผ่านเก่า',
          components_businessPwdmodificationIndex5: 'รหัสผ่าน',
          components_businessPwdmodificationIndex6: 'รหัสผ่าน',
          components_businessPwdmodificationIndex7: 'รหัสผ่านใหม่',
          components_businessPwdmodificationIndex8: 'กรุณาใส่รหัสผ่านใหม่',
          components_businessPwdmodificationIndex9:
            'รหัสผ่านใหม่และรหัสผ่านเก่าต้องไม่ซ้ำกัน',
          components_businessPwdmodificationIndex10:
            'กรุณาใส่รหัสผ่านใหม่ที่นี่',
          components_businessPwdmodificationIndex11: 'ยืนยันรหัสผ่าน',
          components_businessPwdmodificationIndex12: 'กรุณากรอกยืนยันรหัสผ่าน',
          components_businessPwdmodificationIndex13: 'รหัสผ่านไม่ถูกต้อง',
          components_businessPwdmodificationIndex14: 'กรุณากรอกยืนยันรหัสผ่าน',
        },
      },
    },
  },
  utils: {
    verify: {
      index: {
        utilsVerifyIndex1: 'รหัสผ่านต้องมีความยาว 8 ถึง 20 หลัก',
        utilsVerifyIndex2: 'ต้องมีตัวเลข',
        utilsVerifyIndex3: 'ต้องมีตัวพิมพ์ใหญ่',
        utilsVerifyIndex4: 'ต้องมีอักษรตัวพิมพ์เล็ก',
        utilsVerifyIndex5: 'ต้องมีอักษรพิเศษ',
        utilsVerifyIndex6: 'ไม่มีช่องว่าง',
        utilsVerifyIndex7: 'มีอักษรพิเศษไม่ได',
      },
    },
    fetch: {
      index: {
        utilsFetchIndex1: 'การเข้าสู้ระบบถูกปฏิเสธ',
        utilsFetchIndex2:
          'ผู้ใช้ที่เข้าสู่ระบบในปัจจุบันหมดอายุ โปรดลงชื่อเข้าใช้บัญชีอีกครั้งหนึ่ง',
        utilsFetchIndex3: 'โปรดลองอีกครั้งในภายหลัง',
        utilsFetchIndex4: 'โปรดลองอีกครั้งในภายหลัง',
        utilsFetchIndex5:
          'มีปัญหากับเครือข่ายของคุณ โปรดตรวจสอบเครือข่ายแล้วลองอีกครั้ง',
      },
    },
  },
}
