import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Form, Select, Space } from 'antd'
import { useSessionStorageState } from 'ahooks'
import usePermissions from '@/stores/permissions'
import { selectFilterOption } from '@/utils'
import { MenuPath } from '@/router'
import WhiteLayout from '@/layout/white-layout'
import { useI18n } from '@/i18n/context'
import { useApi } from '@/service/api'
import { disableJumpTenant, jumpByTenant } from '@/utils/user-helper'
import styles from './style.module.less'

const SelectTenant = () => {
  const { I18N } = useI18n()
  const [isIndependentApp] = useSessionStorageState<boolean | undefined>(
    'is-independent-app',
    {
      defaultValue: false,
    },
  )
  const { userInfo, clear } = usePermissions()

  const { setState: setPermissionsState } = usePermissions
  const navigate = useNavigate()
  const [listOrg, setListOrg] = useState([])
  const { refreshAsync: getUserAllInfo } = useApi('getUserAllInfo')
  const { runAsync: chooseTenant } = useApi('chooseTenant')
  const { runAsync: chooseOrg } = useApi('chooseOrg')
  const { runAsync: getListOrg } = useApi('getListOrg')
  const { runAsync: getLoginUserInfoApi } = useApi('getLoginUserInfo')
  const [form] = Form.useForm()
  useEffect(() => {
    queryTenantList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (userInfo?.tenantInfos?.length === 1 && listOrg?.length === 1) {
      const tenantId = userInfo?.tenantInfos?.[0]?.tenantId
      const orgId = listOrg?.[0]?.orgId
      onSaveOrg(tenantId, orgId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.tenantInfos, listOrg])
  // 获取租户list
  const queryTenantList = () => {
    getUserAllInfo().then(({ data }) => {
      setPermissionsState({ userInfo: data })
      if (data?.tenantInfos?.length === 1) {
        // selectTenant(data?.tenantInfos[0])
        form.setFieldsValue({ tenantId: data?.tenantInfos[0]?.tenantId })
      }
      setOrgList()
    })
  }
  const onFinish = val => {
    onSaveOrg(val?.tenantId, val?.orgId)
  }
  const onSaveOrg = async (tenantId, orgId) => {
    const { data: tenant } = await chooseTenant({
      tenantId: tenantId,
      tenantMode: disableJumpTenant ? 0 : 1,
    })
    // 选择组织
    await chooseOrg({ orgId: orgId, token: tenant?.token })
    // 判断要跳转的页面
    jumpByTenant({
      userInfo,
      tenant,
      isIndependentApp,
      callback: async () => {
        const { data: userStatusInfo } = await getLoginUserInfoApi(
          tenant?.token,
        )
        window?.opener?.postMessage(
          { token: tenant?.token, userInfo, userStatusInfo },
          '*',
        )
        setPermissionsState({ userStatusInfo, userInfo })
        navigate(MenuPath)
      },
    })
  }
  const onValuesChange = val => {
    if (val?.tenantId) {
      setOrgList(val?.tenantId)
    }
  }
  const setOrgList = (tenantId?: number) => {
    const tempTenantId = tenantId || form.getFieldValue('tenantId')
    if (tempTenantId) {
      getListOrg({ tenantId: tempTenantId }).then(({ data }) => {
        // 切换租户后清除之前选择的组织
        form.resetFields(['orgId'])
        setListOrg(data)
        if (data?.length === 1) {
          form.setFieldsValue({ orgId: data[0]?.orgId })
        }
      })
    }
  }
  return (
    <WhiteLayout>
      <div className={styles.box}>
        <Form
          form={form}
          labelCol={{ span: 5 }}
          onValuesChange={onValuesChange}
          onFinish={val => {
            onFinish(val)
          }}>
          <div className={styles.title}>
            {I18N.get('pages.select-tenant.index.pagesSelecttenantIndex1')}
          </div>
          <Form.Item
            label={I18N.get(
              'pages.select-tenant.index.pagesSelecttenantIndex6',
            )}
            name="tenantId"
            rules={[
              {
                required: true,
                message: I18N.get(
                  'pages.select-tenant.index.pagesSelecttenantIndex7',
                ),
              },
            ]}>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={selectFilterOption}
              options={
                userInfo?.tenantInfos?.map(v => ({
                  label: v?.tenantName,
                  value: v?.tenantId,
                })) || []
              }
            />
          </Form.Item>
          <Form.Item noStyle dependencies={['tenantId']}>
            {({ getFieldValue }) =>
              getFieldValue('tenantId') ? (
                <Form.Item
                  label={I18N.get(
                    'pages.select-tenant.index.pagesSelecttenantIndex2',
                  )}
                  name="orgId"
                  rules={[
                    {
                      required: true,
                      message: I18N.get(
                        'pages.select-tenant.index.pagesSelecttenantIndex3',
                      ),
                    },
                  ]}>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={selectFilterOption}
                    options={
                      listOrg?.map(v => ({
                        label: v?.orgName,
                        value: v?.orgId,
                      })) || []
                    }
                  />
                </Form.Item>
              ) : null
            }
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 5 }}>
            <Space className={styles.btnWrap} wrap>
              <Button type="primary" htmlType="submit">
                {I18N.get('pages.select-tenant.index.pagesSelecttenantIndex4')}
              </Button>
              <Button
                onClick={() => {
                  clear()
                }}>
                {I18N.get('pages.select-tenant.index.pagesSelecttenantIndex5')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </WhiteLayout>
  )
}
export default SelectTenant
