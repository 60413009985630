import { request } from '@/utils/fetch'

/**
 * 刷新token
 * @param token token值或者code值
 * @returns 新token
 */
export const refreshToken = (data: { token: string }) =>
  request({
    url: `/auth/refreshToken`,
    method: 'post',
    ...(data?.token
      ? {
          headers: {
            Authorization: data?.token,
          },
        }
      : {}),
  })

/**
 * 更新密码
 */
export const updatePassword = (data: {
  newPassword: string
  oldPassword: string
}) =>
  request({
    url: `/auth/updatePassword`,
    method: 'post',
    data,
  })

export const getListApp = (data: { orgId: number; tenantId: number }) =>
  request({
    url: `/auth/listApp`,
    method: 'post',
    data,
  })

export const getListOrg = (data: { tenantId: number }) =>
  request({
    url: `/auth/listOrg`,
    method: 'post',
    data,
  })

/**
 * 选择租户
 */
export const chooseTenant = (data: { tenantId: number; tenantMode?: number }) =>
  request({
    url: `/auth/chooseTenant`,
    method: 'post',
    data,
  })

/**
 * 选择组织
 */
export const chooseOrg = (data: { orgId: number; token?: string }) =>
  request({
    url: `/auth/chooseOrg`,
    method: 'post',
    data,
    ...(data?.token
      ? {
          headers: {
            Authorization: data?.token,
          },
        }
      : {}),
  })

/**
 * 获取用户信息
 */
export const getLoginUserInfo = (token?: string) =>
  request({
    url: `/auth/getLoginUserInfo`,
    method: 'post',
    ...(token
      ? {
          headers: {
            Authorization: token,
          },
        }
      : {}),
  })

export const getStaticApiJson = () => request({ url: `/static_api_json` })

/**
 * 获取用户所有信息
 */
export const getUserAllInfo = (token?: string) =>
  request({
    url: `/auth/getUserAllInfo`,
    method: 'post',
    ...(token
      ? {
          headers: {
            Authorization: token,
          },
        }
      : {}),
  })

/**
 * 验证token是否过期
 */
export const verifyToken = () =>
  request({
    url: `/auth/verifyToken`,
    method: 'post',
  })
