import { ApolloProvider } from '@apollo/client'
import { BailuConfigProvider, ErrorBoundary } from '@fruits-chain/react-bailu'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import moment from 'moment'

import zhCN from 'antd/lib/locale/zh_CN'
import { addGlobalUncaughtErrorHandler } from 'qiankun'
import Routes from './router'
import gqlClient from './graphql/client'
import 'dayjs/locale/zh-cn'
import 'moment/locale/zh-cn'
import { I18NContextWrapper } from './i18n/context'
import './App.css'
import usePermissions from './stores/permissions'
import config from './config'

// 防止日期组件中文失效
// eslint-disable-next-line import/no-named-as-default-member
moment.locale('zh-cn')

// eslint-disable-next-line no-console
addGlobalUncaughtErrorHandler(event => console.log(event))

function App() {
  const { token } = usePermissions()
  return (
    <ApolloProvider client={gqlClient}>
      <BailuConfigProvider locale={zhCN} token={token} uploadHost="">
        <I18NContextWrapper>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </I18NContextWrapper>
      </BailuConfigProvider>
    </ApolloProvider>
  )
}

export default App
