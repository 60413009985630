export default {
  /**
   * 全果链水果产业供应链管理平台
   */
  layoutIndex1: '全果链水果产业供应链管理平台',
  /**
               * 首页;
 
               */
  home: '首页',
}
