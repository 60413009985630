import { start, registerMicroApps } from 'qiankun'
import { useDeepCompareEffect } from 'ahooks'
import usePermissions from '@/stores/permissions'
import useCommonLayout from './useCommonLayout'
import { useChildApps } from './components/child-app-side-menu/useChildApps'
import { getChildAppKeys } from './components/child-app-side-menu/configData'
// 加载apps应用hooks，逻辑抽离
const useRegisterApps = () => {
  const permissionsStore = usePermissions()
  const { apps } = permissionsStore
  const allChildApps = useChildApps()

  const { setState } = usePermissions
  useDeepCompareEffect(() => {
    setState({ appLoading: false })
    if (apps && apps.length > 0) {
      let microApp = apps
        ?.filter(v => {
          const hasChildApp = getChildAppKeys().includes(v.code)
          return !hasChildApp
        })
        .map(item => ({
          // ...item,
          name: item?.code,
          entry: item?.entryUrl,
          container: '#micro-app',
          activeRule: `/${item.code}`,
          props: {
            // 向子应用传递数据
            basePath: `/${item.code}`,
            useAppState: usePermissions,
            useAppLayoutState: useCommonLayout,
          },
          loader: (appLoading: boolean) => {
            setState({ appLoading })
          },
        }))
      let childMicroApp = []
      allChildApps?.forEach(v => {
        const currentIndex = childMicroApp?.findIndex(
          app => app?.entry === v?.entryUrl,
        )
        if (currentIndex !== -1) {
          childMicroApp[currentIndex]['activeRule'] = [
            ...childMicroApp[currentIndex]['activeRule'],
            `/${v?.parentCode}/${v.code}`,
          ]
        } else {
          childMicroApp?.push({
            // ...item,
            name: v?.code,
            entry: v?.entryUrl,
            container: '#micro-app',
            activeRule: [`/${v?.parentCode}/${v.code}`],
            props: {
              // 向子应用传递数据
              basePath: `/${v?.parentCode}`,
              useAppState: usePermissions,
              useAppLayoutState: useCommonLayout,
            },
          })
        }
      })

      registerMicroApps([...microApp, ...childMicroApp])
      start({
        sandbox: {
          /** 开启过后会让 子应用模态框样式丢失，而使用的父应用的 */
          strictStyleIsolation: false,
          experimentalStyleIsolation: false,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apps, allChildApps])
}

export default useRegisterApps
