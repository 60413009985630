/**
 * 判断str是否是json字符串
 * @param str
 */
export const isJSON = (str: string) => {
  if (typeof str === 'string') {
    try {
      const obj = JSON.parse(str)
      if (typeof obj === 'object' && obj) {
        return true
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }
}
/**
 * 判断str是否是http开头
 * @param boolean
 */
export const ishttp = domain => {
  let patt = /^http(s)?:\/\/.+/
  if (!patt.test(domain)) {
    return false
  } else {
    return true
  }
}
export function selectFilterOption(input, option) {
  const label = option.label || option.children
  return label?.indexOf(input) >= 0
}

export const getParameters = (name?: string) => {
  const url = window.location.search
  let parametersObj = {}

  try {
    if (url.split('?')?.[1]) {
      parametersObj = JSON.parse(
        `{"${decodeURI(url.split('?')[1])
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`,
      )
    }
  } catch (error) {}
  return name ? parametersObj?.[name] : parametersObj
}
