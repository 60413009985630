import create from 'zustand'

import { assertsIsMobile } from '@/hooks/useIsMobile'

type ILayoutState = {
  renderHeaderLeft: () => React.ReactNode
  renderHeaderRight: () => React.ReactNode
  /** 侧边栏展开or关闭 */
  menuShown: boolean
}

const useCommonLayout = create<ILayoutState>(() => ({
  renderHeaderLeft: null,
  renderHeaderRight: null,
  menuShown: !assertsIsMobile(),
}))

export default useCommonLayout
