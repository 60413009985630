export default {
  /**
   * 选择组织;
   */
  pagesSelecttenantIndex1: '选择租户/组织',
  /**
   * 组织;
   */
  pagesSelecttenantIndex2: '组织',
  /**
   * 请选择组织;
   */
  pagesSelecttenantIndex3: '请选择组织!',
  /**
   * 确定;
   */
  pagesSelecttenantIndex4: '确定',
  /**
   * 返回登录;
   */
  pagesSelecttenantIndex5: '返回登录',
  /**
   * 选择租户;
   */
  pagesSelecttenantIndex6: '租户',
  /**
   * 请选择租户！;
   */
  pagesSelecttenantIndex7: '请选择租户！',
}
