import { Modal } from 'antd'
import qs from 'qs'
import usePermissions, {
  permissionStore,
  UserInfoType,
} from '@/stores/permissions'
import { env } from '@/config'
/**
 * 去登录并监听发送回来的登录信息
 */
export const tokenInvalid = () => {
  const { token, loginModalIsShown, clear } = permissionStore.getState()
  // 过滤掉无token导致的鉴权失败（由路由拦截处理）
  if (!loginModalIsShown && !!token) {
    permissionStore.setState({ loginModalIsShown: true })
    Modal.warning({
      title: '系统提示',
      content: '登录状态已失效，请重新登录',
      onOk() {
        sessionStorage.setItem(
          'REDIRECT_URL',
          `${location.pathname}${location.search}`,
        )
        permissionStore.setState({ loginModalIsShown: false })
        clear()
      },
      okText: '确定',
    })
  }
}

// export const disableJumpTenant = true
export const disableJumpTenant = env === 'dev'

export const jumpByTenant = ({
  callback,
  userInfo,
  tenant,
  redirect,
  isIndependentApp,
}: {
  // 用户全部的信息
  userInfo: UserInfoType
  redirect?: string
  // 当前选择的租户
  tenant: {
    tenantId: number
    tenantName: string
    token: string
    userName: string
    userId: number
    [key: string]: any
  }
  isIndependentApp?: boolean
  // 选择后callback，如果是跳转到另一个页面那就直接跳转了，当前页面的话就用callback进行操作
  callback?: () => void
}) => {
  const { token, tenantId } = tenant
  const currentTenant = userInfo?.tenantInfos?.find(
    v => v?.tenantId === tenantId,
  )
  if (currentTenant) {
    if (disableJumpTenant || isIndependentApp) {
      callback?.()
      return false
    }
    if (currentTenant?.urlPrefix === window.location?.origin) {
      usePermissions?.setState({
        token,
      })
      callback?.()
    } else {
      if (currentTenant?.urlPrefix) {
        let replaceHref = `${currentTenant?.urlPrefix}?${qs.stringify({
          token: encodeURIComponent(token),
          redirect: redirect ? encodeURIComponent(redirect) : redirect,
        })}`

        window.location.replace(replaceHref)
      } else {
        callback?.()
      }
    }
  }
}
