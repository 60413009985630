import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import { ModalProps, Spin, Modal, Select } from 'antd'
import cs from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import { RightOutlined } from '@ant-design/icons'
import usePermissions from '@/stores/permissions'
import { useI18n } from '@/i18n/context'
import { useApi } from '@/service/api'
import { selectFilterOption } from '@/utils'
import { disableJumpTenant, jumpByTenant } from '@/utils/user-helper'
import styles from './style.module.less'

export interface Handles {
  /**
   * 打开弹框
   */
  open: () => void
  /**
   * 关闭弹框
   */
  close: () => void
}
interface Props extends Omit<ModalProps, 'visible' | 'okButtonProps'> {}
/** 重置密码弹框组件 */
const SelectAppModal = forwardRef<Handles, Props>(({ ...modalProps }, ref) => {
  const { userStatusInfo, apps, userInfo } = usePermissions()
  const { setState } = usePermissions
  const { I18N } = useI18n()
  let location = useLocation()
  let navigate = useNavigate()
  const { loading, runAsync: getListApp } = useApi('getListApp')
  const { loading: orgListLoading, runAsync: getListOrg } = useApi('getListOrg')
  const { runAsync: chooseTenant } = useApi('chooseTenant')
  const { runAsync: chooseOrg } = useApi('chooseOrg')
  const { refreshAsync: getLoginUserInfo } = useApi('getLoginUserInfo')
  // 模态框显示与否
  const [visible, setVisible] = useState(false)
  // 选择系统时的loading
  const [okLoading, setOkLoading] = useState(false)
  // 组织列表
  const [orgList, setOrgList] = useState([])
  // 系统列表
  const [appList, setAppList] = useState([])
  // 当前选择租户id
  const [tenantId, setTenantId] = useState(null)
  // 当前选择组织id
  const [orgId, setOrgId] = useState(null)
  // 当前选择系统id
  const [appId, setAppId] = useState(null)
  // 是否显示租户列表，只有一个租户的时候不显示
  const showTenantList = useMemo(() => {
    return userInfo?.tenantInfos?.length >= 1
  }, [userInfo?.tenantInfos])

  /** 打开弹框 */
  const open = () => {
    setVisible(true)
    // 初始化租户
    setTenantId(userStatusInfo?.tenantId)
    // 初始化组织
    setOrgId(userStatusInfo?.organizationId)
    const currentApp = apps?.find(v => {
      return location.pathname.startsWith(`/${v?.code}`)
    })
    // 初始化当前系统
    setAppId(currentApp?.appId)
  }
  /** 关闭弹框 */
  const close = useCallback(() => {
    setVisible(false)
  }, [])
  useEffect(() => {
    if (tenantId) {
      getListOrg({ tenantId }).then(({ data }) => {
        setOrgList(data)
        if (data?.length > 0 && !orgId) {
          setOrgId(data?.[0]?.orgId)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId])
  useEffect(() => {
    if (orgId) {
      getListApp({ orgId, tenantId }).then(({ data }) => {
        const resList = [
          ...(data
            ?.map(v => ({
              ...v,
              code: v?.code?.toLowerCase(),
            }))
            ?.filter(v => !!v?.entryUrl) || []),
        ]
        setAppList(resList)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId])
  const onOk = async () => {
    setOkLoading(true)
    // 可以选租户的时候选租户
    if (showTenantList) {
      if (userStatusInfo?.tenantId !== tenantId) {
        const { data: tenant } = await chooseTenant({
          tenantId,
          tenantMode: disableJumpTenant ? 0 : 1,
        })
        // 选择完租户后选择组织
        await chooseOrg({ orgId, token: tenant?.token })
        // 请求最新的系统列表并设置到apps
        const currentApp = appList?.find(v => v?.appId === appId)
        const codeRedirect = `/${currentApp?.code}`
        // 判断要跳转的页面
        jumpByTenant({
          userInfo,
          tenant,
          redirect: codeRedirect,
          callback: async () => {
            orgSelectSuccess()
          },
        })
      } else {
        // 选择完租户后选择组织
        await chooseOrg({ orgId })
        orgSelectSuccess()
      }
    } else {
      // 选择完租户后选择组织
      await chooseOrg({ orgId })
      orgSelectSuccess()
    }
  }
  const orgSelectSuccess = async () => {
    const { data } = await getLoginUserInfo()
    setState({ userStatusInfo: data })
    // 请求最新的系统列表并设置到apps
    const currentApp = appList?.find(v => v?.appId === appId)
    const prevApp = apps?.find(v => {
      return location.pathname.startsWith(`/${v?.code}`)
    })
    setVisible(false)
    setOkLoading(false)
    setState({ apps: appList })
    if (prevApp?.appId === currentApp?.appId) {
      // 切换了租户，不切系统，这里任务还是同一个子应用就不会重新加载子应用多权限，所以这里多加了一个跳转页面触发子应用重新加载
      navigate(`/redirect?redirect=/${currentApp?.code}`)
    } else {
      navigate(`/${currentApp?.code}`)
    }
  }
  /** 对外暴露接口 */
  useImperativeHandle(ref, () => ({
    open,
    close,
  }))

  return (
    <Modal
      {...(showTenantList ? { width: 800 } : {})}
      okText={I18N.get(
        'components.business.selectAppModal.index.components_businessSelectappmodalIndex1',
      )}
      title={I18N.get(
        'components.business.selectAppModal.index.components_businessSelectappmodalIndex2',
      )}
      visible={visible}
      okButtonProps={{
        loading: loading || okLoading,
        disabled: !(tenantId && orgId && appId),
      }}
      bodyStyle={{ padding: '0 12px' }}
      onCancel={() => {
        close()
      }}
      onOk={onOk}
      {...modalProps}>
      <div className={styles.selectWrap}>
        {/* 选择租户 */}
        {showTenantList ? (
          <div className={styles.selectList}>
            <div className={styles.listTitle}>
              {I18N.get(
                'components.business.selectAppModal.index.components_businessSelectappmodalIndex3',
              )}
            </div>
            <div className={styles.list}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={selectFilterOption}
                value={tenantId}
                className={styles.listSelect}
                onChange={val => {
                  setTenantId(val)
                  setOrgId(null)
                  setAppId(null)
                }}
                options={userInfo?.tenantInfos?.map(v => ({
                  value: v?.tenantId,
                  label: v?.tenantName,
                }))}
              />
            </div>
          </div>
        ) : null}
        {/* 选择组织 */}
        <div className={styles.selectList}>
          <div className={styles.listTitle}>
            {I18N.get(
              'components.business.selectAppModal.index.components_businessSelectappmodalIndex4',
            )}
          </div>
          {orgListLoading ? (
            <div className={styles.loading}>
              <Spin />
            </div>
          ) : (
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={selectFilterOption}
              value={orgId}
              className={styles.listSelect}
              onChange={val => {
                setOrgId(val)
                setAppId(null)
              }}
              options={orgList?.map(v => ({
                value: v?.orgId,
                label: v?.orgName,
              }))}
            />
          )}
        </div>
        {/* 选择系统 */}
        <div className={styles.selectList}>
          <div className={styles.listTitle}>
            {I18N.get(
              'components.business.selectAppModal.index.components_businessSelectappmodalIndex5',
            )}
          </div>
          {loading ? (
            <div className={styles.loading}>
              <Spin />
            </div>
          ) : appList?.length > 0 && orgList?.length > 0 ? (
            <div className={styles.list}>
              {appList?.map((v, i) => {
                return (
                  <div
                    className={cs(
                      styles.item,
                      v?.appId === appId ? styles.active : null,
                    )}
                    key={i}
                    onClick={() => {
                      setAppId(v?.appId)
                    }}>
                    {v?.name}
                    <RightOutlined />
                  </div>
                )
              })}
            </div>
          ) : (
            <div className={styles.noData}>
              {I18N.get(
                'components.business.selectAppModal.index.components_businessSelectappmodalIndex6',
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
})
export default SelectAppModal
