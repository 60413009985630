import qs from 'querystring'
import useUrlState from '@ahooksjs/use-url-state'
import React, { FC, useEffect } from 'react'
import dayjs from 'dayjs'
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  LinkOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { Ellipsis } from '@fruits-chain/react-bailu'
import {
  useGetHelpManageByIdLazyQuery,
  useGetUpAndDownLazyQuery,
} from '@/graphql/gqls/help-center/__generated__/index.generated'
import { GetUpAndDownInput } from '@/graphql/generated/types'
import { SearchValueType } from '../search-input'
import styles from './index.module.less'
export interface RichTextContentProps {}
const RichTextContent: FC<RichTextContentProps> = () => {
  const [urlParams] = useUrlState<
    { id: string } & GetUpAndDownInput & SearchValueType
  >()
  const [getHelpManageById, { data }] = useGetHelpManageByIdLazyQuery()
  const [getUpAndDown, { data: upAndDownData }] = useGetUpAndDownLazyQuery()
  useEffect(() => {
    if (urlParams?.id) {
      getHelpManageById({ variables: { input: +urlParams?.id } })
      let res = {
        enable: 1,
        id: +urlParams?.id,
      }
      if (urlParams?.appId) {
        res['appId'] = +urlParams?.appId
      }
      if (urlParams?.keyword) {
        res[urlParams?.searchType || 'contentKeyword'] = urlParams?.keyword
      }
      if (urlParams?.helpType) {
        res['helpType'] = urlParams?.helpType
      }
      getUpAndDown({ variables: { input: res } })
    }
  }, [
    getHelpManageById,
    getUpAndDown,
    urlParams?.appId,
    urlParams?.helpType,
    urlParams?.id,
    urlParams?.keyword,
    urlParams?.searchType,
  ])

  return (
    <div>
      <div className={styles.breadcrumb}>
        {data?.getHelpManageById?.helpTypeLabel} /{' '}
        <span>{data?.getHelpManageById?.title}</span>
      </div>
      <div className={styles.title}>{data?.getHelpManageById?.title}</div>
      {data?.getHelpManageById?.createTime ? (
        <div className={styles.date}>
          更新时间：
          {dayjs(data?.getHelpManageById?.createTime).format(
            'YYYY-MM-DD HH:mm',
          )}
        </div>
      ) : null}
      {data?.getHelpManageById?.file?.length > 0 ? (
        <div className={styles.linkWrap}>
          {data?.getHelpManageById?.file?.map((v, i) => {
            return (
              <div key={i} className={styles.linkItem}>
                <LinkOutlined className={styles.linkIcon} />
                <a
                  href={`${v?.fileUrl}?response-content-type=application/octet-stream`}
                  target="_blank"
                  download
                  className={styles.link}>
                  {v?.filename}
                </a>
              </div>
            )
          })}
        </div>
      ) : null}
      <div
        className={styles.textContent}
        dangerouslySetInnerHTML={{
          __html: data?.getHelpManageById?.content,
        }}
      />
      {upAndDownData?.getUpAndDown?.up?.id ||
      upAndDownData?.getUpAndDown?.down?.id ? (
        <div className={styles.upAndDown}>
          {upAndDownData?.getUpAndDown?.up?.id && (
            <Link
              to={`/help-center?${qs.stringify({
                ...(urlParams || {}),
                id: upAndDownData?.getUpAndDown?.up?.id,
                helpType: upAndDownData?.getUpAndDown?.up?.helpType,
              })}`}>
              <div className={styles.upAndDownLeft}>
                <ArrowLeftOutlined className={styles.upAndDownLeftIcon} />
                <Ellipsis.Tooltip
                  content={upAndDownData?.getUpAndDown?.up?.title}
                  direction="end"
                />
              </div>
            </Link>
          )}
          {upAndDownData?.getUpAndDown?.down?.id && (
            <Link
              to={`/help-center?${qs.stringify({
                ...(urlParams || {}),
                id: upAndDownData?.getUpAndDown?.down?.id,
                helpType: upAndDownData?.getUpAndDown?.down?.helpType,
              })}`}>
              <div className={styles.upAndDownRight}>
                <Ellipsis.Tooltip
                  content={upAndDownData?.getUpAndDown?.down?.title}
                  direction="end"
                />
                <ArrowRightOutlined className={styles.upAndDownRightIcon} />
              </div>
            </Link>
          )}
        </div>
      ) : null}
    </div>
  )
}
export default RichTextContent
