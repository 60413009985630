import { useState } from 'react'
import { useDeepCompareEffect } from 'ahooks'
import { MicroConfigType } from '@/micro.config'
import useChildAppStore from './stores/useChildAppStore'
import { CustomRouteConfig } from './useRoutesData'

const getApps = (routes: CustomRouteConfig[], apps: MicroConfigType[] = []) => {
  let resApps = apps
  routes?.forEach(v => {
    if (!apps?.find(app => app?.code === v?.appMicroConfig?.code)) {
      if (v?.appMicroConfig) {
        resApps?.push(v?.appMicroConfig)
      }
    }
    if (v?.children?.length > 0) {
      resApps = getApps(v?.children, resApps)
    }
  })
  return resApps
}
export const useChildApps = () => {
  const { allRoutes } = useChildAppStore()
  const [allChildApps, setAllChildApps] = useState([])
  useDeepCompareEffect(() => {
    let allApps = []
    for (const key in allRoutes) {
      if (Object.prototype.hasOwnProperty.call(allRoutes, key)) {
        const element = allRoutes[key]

        allApps = [
          ...allApps,
          ...(getApps(element)?.map(v => {
            return { ...v, parentCode: key }
          }) || []),
        ]
      }
    }
    setAllChildApps(allApps)
  }, [allRoutes])

  return allChildApps
}
