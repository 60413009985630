import qs from 'querystring'
import React, { FC, useEffect, useRef, useState } from 'react'
import { Typography } from 'antd'
import { Link } from 'react-router-dom'
import useUrlState from '@ahooksjs/use-url-state'
import omit from 'lodash/omit'
import { PageHelpManageQuery } from '@/graphql/gqls/help-center/__generated__/index.generated'
import styles from './index.module.less'
export interface ListItemProps {
  data: PageHelpManageQuery['pageHelpManage']['records'][number]
}
const { Paragraph } = Typography
const ListItem: FC<ListItemProps> = ({ data }) => {
  const [urlParams] = useUrlState()
  const [content, setContent] = useState('')
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current?.innerText) {
      setContent(ref.current?.innerText)
    }
  }, [data])
  return (
    <Link
      to={`/help-center?${qs.stringify({
        ...(omit(urlParams, ['pageCurrent', 'pageSize']) || {}),
        id: data?.id,
        helpType: data?.helpType,
      })}`}>
      <div className={styles.listItem}>
        <div className={styles.title}>{data?.title}</div>
        <div
          style={{ display: 'none' }}
          ref={ref}
          dangerouslySetInnerHTML={{
            __html: data?.content,
          }}
        />

        <Paragraph
          ellipsis={{
            rows: 3,
            expandable: false,
            symbol: 'more',
          }}>
          {content}
        </Paragraph>
      </div>
    </Link>
  )
}
export default ListItem
