export default {
  /**
   * 确定
   */
  components_businessPwdmodificationIndex1: '确定',
  /**
   * 修改密码
   */
  components_businessPwdmodificationIndex2: '修改密码',
  /**
   * 修改成功
   */
  components_businessPwdmodificationIndex3: '修改成功',
  /**
   * 密码
   */
  components_businessPwdmodificationIndex4: '旧密码',
  /**
   * 请输入密码
   */
  components_businessPwdmodificationIndex5: '请输入密码',
  /**
   * 请输入密码
   */
  components_businessPwdmodificationIndex6: '请输入密码',
  /**
   * 新密码
   */
  components_businessPwdmodificationIndex7: '新密码',
  /**
   * 请输入新密码
   */
  components_businessPwdmodificationIndex8: '请输入新密码',
  /**
   * 请输入新密码
   */
  components_businessPwdmodificationIndex9: '新密码不能与旧密码一样',
  /**
   * 确认密码
   */
  components_businessPwdmodificationIndex10: '请输入新密码',
  /**
   * 请确认密码
   */
  components_businessPwdmodificationIndex11: '确认密码',
  /**
   * 请确认密码
   */
  components_businessPwdmodificationIndex12: '请确认密码',
  /**
   * 请确认密码
   */
  components_businessPwdmodificationIndex13: '密码不一致',
  /**
   * 请确认密码
   */
  components_businessPwdmodificationIndex14: '请确认密码',
}
