import React, { useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { CaretDownOutlined } from '@ant-design/icons'
import { useTitle } from 'ahooks'
import usePermissions from '@/stores/permissions'
import SelectAppModal, {
  Handles,
} from '@/components/_business/select-app-modal'
import { useI18n } from '@/i18n/context'
import { MenuPath } from '@/router'

import styles from './style.module.less'

const SelectApp: React.FC = () => {
  const { I18N } = useI18n()
  const { userStatusInfo, apps } = usePermissions()
  useTitle(
    `${
      userStatusInfo?.tenantName ? `${userStatusInfo?.tenantName} - ` : ''
    }全果链水果产业供应链管理平台`,
  )

  let location = useLocation()
  const selectAppModalRef = useRef<Handles>(null)
  const currentApp = useMemo(() => {
    return apps?.find(v => {
      return location.pathname.startsWith(`/${v?.code}`)
    })
  }, [apps, location.pathname])

  return (
    <>
      <div
        className={styles.appName}
        onClick={() => {
          selectAppModalRef.current?.open()
        }}>
        <span className={styles.systemName}>
          {`${
            location.pathname === MenuPath
              ? I18N.get('layout.index.home')
              : currentApp?.name || ''
          }`}
        </span>
        <CaretDownOutlined style={{ marginLeft: '8px' }} />
      </div>
      <SelectAppModal ref={selectAppModalRef} />
    </>
  )
}

export default SelectApp
