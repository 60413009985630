import React, { useEffect, useMemo, useState } from 'react'
import { QrcodeOutlined } from '@ant-design/icons'
import QRCode from 'qrcode.react'

import { Popover, Spin } from 'antd'
import { useLocation } from 'react-router-dom'
import { useApi } from '@/service/api'
import usePermissions from '@/stores/permissions'
import styles from './style.module.less'

const AppDownload: React.FC = () => {
  const { apps } = usePermissions()
  const [downloadInfo, setDownloadInfo] =
    useState<{ downloadUrl: string; name: string }>(null)
  const location = useLocation()
  const currentApp = useMemo(() => {
    return apps?.find(v => {
      return location.pathname.startsWith(`/${v?.code}`)
    })
  }, [apps, location])
  const { loading, runAsync } = useApi('getAppDownloadUrl')
  useEffect(() => {
    if (currentApp?.appId) {
      runAsync({ appType: currentApp?.appId }).then(data => {
        if (data?.data) {
          setDownloadInfo(data?.data)
        }
      })
    } else {
      setDownloadInfo(null)
    }
  }, [currentApp?.appId, runAsync])
  const content = (
    <div className={styles.codeWrap}>
      <div className={styles.codeImgBox}>
        {loading ? (
          <Spin />
        ) : (
          <QRCode
            value={downloadInfo?.downloadUrl}
            size={125}
            fgColor="#000000"
            className={styles.codeImg}
          />
        )}
      </div>
      <p className={styles.appName}>{downloadInfo?.name}</p>
      <div className={styles.tips}>
        安卓用户请使用浏览器扫描二维码下载 IOS用户请联系管理员下载
      </div>
    </div>
  )
  return downloadInfo?.downloadUrl ? (
    <Popover placement="bottomRight" content={content} trigger="click">
      <div className={styles.icon}>
        <QrcodeOutlined style={{ marginRight: '18px' }} />
      </div>
    </Popover>
  ) : null
}

export default AppDownload
