import useUrlState from '@ahooksjs/use-url-state'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  useBoolean,
  useDeepCompareEffect,
  useMount,
  useUpdateEffect,
} from 'ahooks'
import usePermissions from '@/stores/permissions'
import { useApi } from '@/service/api'
import { disableJumpTenant } from '@/utils/user-helper'
import { MenuPath } from '.'
/**
 * 监听token改变：跳转到登录页
 * 监听组织改变：请求最新apps用于加载微应用
 * onMount:判断是否登录，没登录跳转到登录页面,然后判断是否选择了租户组织，如果没有选择跳转到选择租户页面
 * 再判断是否有重定向参数，有就调整到重定向页面
 */
const useIsLogin = () => {
  const [state] = useUrlState()
  const navigate = useNavigate()
  const {
    token,
    bossUrl,
    userStatusInfo: originalUserStatusInfo,
  } = usePermissions()

  const [loading, { setFalse, setTrue }] = useBoolean(false)
  const { pathname } = useLocation()
  const urlToken = state?.token ? decodeURIComponent(state?.token) : ''
  const newToken = urlToken || token

  const { runAsync: getListApp } = useApi('getListApp')
  // 获取用户信息接口
  const { runAsync: getUserAllInfo } = useApi('getUserAllInfo')
  // 获取用户状态信息接口
  const { runAsync: getLoginUserInfoApi } = useApi('getLoginUserInfo')
  // 获取用户信息
  useUpdateEffect(() => {
    if (!newToken) {
      goLoginPage()
    }
    // 如果有新token保存下来
    if (newToken && urlToken && urlToken !== token) {
      usePermissions?.setState({
        token: newToken,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newToken])

  // // 获取appList,不然基座不加载子应用
  useDeepCompareEffect(() => {
    if (originalUserStatusInfo?.organizationId) {
      setTrue()
      getListApp({ orgId: originalUserStatusInfo?.organizationId }).then(
        ({ data }) => {
          usePermissions?.setState({
            apps: [
              ...(data
                ?.map(v => ({
                  ...v,
                  code: v?.code?.toLowerCase(),
                }))
                ?.filter(v => !!v?.entryUrl) || []),
            ],
          })
          setFalse()
        },
      )
    }
  }, [originalUserStatusInfo?.organizationId])
  /** 根据用户状态跳转页面 */
  const pageRedirect = (userInfo?: any) => {
    if (!userInfo?.organizationId) {
      navigate(`/select-tenant`)
    } else {
      if (pathname === '/') {
        if (state.redirect) {
          navigate(decodeURIComponent(state.redirect))
        } else {
          navigate(MenuPath)
        }
      }
    }
  }
  const getUserInfo = () => {
    setTrue()
    const userAllInfoRequest = getUserAllInfo(newToken)
    const userInfoRequest = getLoginUserInfoApi(newToken)
    Promise.all([userAllInfoRequest, userInfoRequest])
      .then(([data1, data2]) => {
        const { data: userInfo } = data1
        const { data: userStatusInfo } = data2
        usePermissions?.setState({
          userInfo,
          userStatusInfo,
        })
        /** 三方进入时不需要变动 */
        if (state?.isIndependentApp) {
          return false
        }
        pageRedirect(userStatusInfo)
      })
      .catch(e => {
        navigate(`/login`)
      })
      .finally(() => {
        setFalse()
      })
  }
  const goLoginPage = () => {
    if (pathname !== '/login') {
      if (!disableJumpTenant && bossUrl) {
        window.location.href = `${bossUrl}/login?event=logout`
      } else {
        navigate(`/login`)
      }
    }
  }
  useMount(() => {
    // 是否是登录状态
    if (newToken && state?.event !== 'logout') {
      // 是否有新token，如果有就保存
      if (urlToken && urlToken !== token) {
        usePermissions?.setState({
          token: newToken,
        })
      }
      getUserInfo()
    } else {
      goLoginPage()
    }
  })
  return { loading }
}

export default useIsLogin
