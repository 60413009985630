import { Dropdown, Menu, Modal } from 'antd'
import React, { useEffect, useRef } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import usePermissions from '@/stores/permissions'
import ResetPassword, { Handles } from '@/components/_business/pwd-modification'
import { useI18n } from '@/i18n/context'
import { useIsMobile } from '@/hooks/useIsMobile'
import styles from './style.module.less'
const enum handleKey {
  /** 退出登录 */
  logout = 'logout',
  /** 修改密码 */
  resetPassword = 'resetPassword',
}
const UserInfo: React.FC = () => {
  const resetPasswordModalRef = useRef<Handles>()
  const { clear, userInfo, userStatusInfo } = usePermissions()
  const { I18N } = useI18n()
  const isMobile = useIsMobile()
  // 用户第一次进来需要修改密码
  useEffect(() => {
    if (userInfo?.needUpdatePassword) {
      resetPasswordModalRef.current.open()
    }
  }, [userInfo?.needUpdatePassword])
  const menuHandle = (key: handleKey) => {
    switch (key) {
      case 'logout':
        Modal.confirm({
          title: I18N.get(
            'layout.components.userInfo.index.layoutComponentsUserinfoIndex1',
          ),
          icon: <ExclamationCircleOutlined />,
          okText: I18N.get(
            'layout.components.userInfo.index.layoutComponentsUserinfoIndex2',
          ),
          cancelText: I18N.get(
            'layout.components.userInfo.index.layoutComponentsUserinfoIndex3',
          ),
          maskClosable: false,
          onOk: () => clear(),
        })
        break
      case 'resetPassword':
        resetPasswordModalRef.current.open()
        break
      default:
        break
    }
  }
  // 菜单
  const menuJSX = (
    <Menu>
      <Menu.Item
        onClick={() => {
          menuHandle(handleKey.resetPassword)
        }}
        key={handleKey.resetPassword}>
        {I18N.get(
          'layout.components.userInfo.index.layoutComponentsUserinfoIndex4',
        )}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          menuHandle(handleKey.logout)
        }}
        key={handleKey.logout}>
        {I18N.get(
          'layout.components.userInfo.index.layoutComponentsUserinfoIndex5',
        )}
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <Dropdown overlay={menuJSX}>
        <div className={styles.userInfo}>
          {[
            isMobile ? undefined : userStatusInfo?.tenantName,
            isMobile ? undefined : userStatusInfo?.organizationName,
            userInfo?.userName,
          ]
            .filter(Boolean)
            .join(' - ')}
        </div>
      </Dropdown>
      <ResetPassword ref={resetPasswordModalRef} />
    </>
  )
}
export default UserInfo
