/**
 * 配置文件
 */
interface CommonConfig {
  /** 权限key */
  authKey: string
  /** 系统是否需鉴权 */
  authorization: boolean
}
interface EnvConfig {
  /** api host */
  apiHost: string
  /** 上传host */
  uploadHost: string
  /** 项目根路径 */
  baseUrl: string
}
export interface IConfig extends CommonConfig, EnvConfig {}

export type Env = 'dev' | 'test' | 'prod'

export const env = process.env.REACT_APP_CONFIG_ENV as Env
// 配置(公共)
const commonConfig: CommonConfig = {
  authKey: 'Authorization',
  authorization: false,
}

/** 是否是起的本地环境 */
export const isLocal = process.env.REACT_APP_IS_LOCAL as Env
// 配置(根据环境变量区分)
export const envConfig: Record<Env, EnvConfig> = {
  // 开发环境
  dev: {
    apiHost: 'http://192.168.10.233:3020/operation', // 开发地址 http://192.168.10.233:10004 测试地址 https://peach-test.hjgpscm.com
    uploadHost: '',
    baseUrl: isLocal ? '/' : '/operation/',
  },
  // 测试环境
  test: {
    apiHost: '',
    uploadHost: '',
    baseUrl: '/operation/',
  },
  // 生产环境
  prod: {
    apiHost: '',
    uploadHost: '',
    baseUrl: '/operation/',
  },
}
const config = { ...commonConfig, ...envConfig[env] }
export default config
