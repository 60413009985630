import { Button } from 'antd'
import React from 'react'
import useUrlState from '@ahooksjs/use-url-state'
import { useI18n } from '@/i18n/context'
import styles from './style.module.less'

interface SsoLoginProps {
  jumpLogin: () => void
}

const SsoLogin: React.FC<SsoLoginProps> = ({ jumpLogin }) => {
  const { I18N } = useI18n()
  const [state] = useUrlState()

  return (
    <Button
      loading={state?.code}
      type="primary"
      size="large"
      onClick={jumpLogin}
      className={styles.btn}>
      {I18N.get('pages.login.index.login')}
    </Button>
  )
}

export default SsoLogin
