import dayjs from 'dayjs'
import usePermissions from '@/stores/permissions'
import { useApi } from '@/service/api'
/**
 * 每次进入系统刷新token,节流间隔3个小时
 */
const useRefreshToken = () => {
  const { token, refreshTokenTime } = usePermissions()
  const { setState } = usePermissions
  // const { runAsync: refreshToken } = useApi('refreshToken')
  // 用户状态信息在窗口聚焦时自动刷新
  useApi('refreshToken', {
    refreshOnWindowFocus:
      token && dayjs().diff(dayjs(refreshTokenTime), 'hour') > 3,
    // 因为自动刷新，成功逻辑写在接口调用
    onSuccess: res => {
      setState({ token: res?.data, refreshTokenTime: dayjs().valueOf() })
    },
  })
}

export default useRefreshToken
