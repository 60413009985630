import useUrlState from '@ahooksjs/use-url-state'
import React, { FC } from 'react'
import RichTextContent from './components/rich-text-content'
import SearchInput, { SearchValueType } from './components/search-input'
import styles from './index.module.less'
import HelpMenus from './components/menu'
import HelpList from './components/help-list'
import { defaultParams } from './const'
export interface HelpCenterProps {}
type urlParamsType = { id?: string } & SearchValueType
const HelpCenter: FC<HelpCenterProps> = () => {
  const [urlParams, setUrlParams] = useUrlState<urlParamsType>()

  return (
    <div className={styles.helpPage}>
      <div className={styles.header}>
        <div className={styles.pageTitle}>帮助中心</div>
        <div className={styles.searchInput}>
          <SearchInput
            defaultValue={{ searchType: 'contentKeyword' }}
            value={{
              searchType: urlParams?.searchType,
              keyword: urlParams?.keyword,
            }}
            onChange={val => {
              setUrlParams({ ...defaultParams, ...val })
            }}
          />
        </div>
      </div>
      <div className={styles.mainContent}>
        <div className={styles.contentWrap}>
          <div className={styles.menu}>
            <HelpMenus />
          </div>
          <div className={styles.body}>
            <div className={styles.content}>
              {urlParams?.id ? <RichTextContent /> : <HelpList />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default HelpCenter
