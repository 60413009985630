import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import usePermissions from '@/stores/permissions'
/**
 * 当前加载的那一个子应用
 */
export const useCurrentApp = () => {
  const { apps } = usePermissions()
  const { pathname } = useLocation()
  const currentApp = useMemo(() => {
    return apps?.find(v => {
      return pathname?.startsWith(`/${v?.code}`)
    })
  }, [pathname, apps])
  return currentApp
}
