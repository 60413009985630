export default {
  /**
   * 拒绝访问;
   */
  utilsFetchIndex1: '拒绝访问',
  /**
   * 登录已过期，请重新登录;
   */
  utilsFetchIndex2: '登录已过期，请重新登录',
  /**
   * 请求超时，请稍后再试;
   */
  utilsFetchIndex3: '请求超时，请稍后再试',
  /**
   * 报告！服务器出了点小问题，稍后再试试...;
   */
  utilsFetchIndex4: '报告！服务器出了点小问题，稍后再试试...',
  /**
   * 您的网络出现问题，请检查网络重试;
   */
  utilsFetchIndex5: '您的网络出现问题，请检查网络重试',
}
