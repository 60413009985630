import React from 'react'
import { useI18n } from '@/i18n/context'
import styles from './style.module.less'
const WhiteLayout: React.FC = ({ children }) => {
  /** 今年的年份 */
  const currentYear = new Date().getFullYear()
  const { I18N } = useI18n()
  return (
    <div className={styles.page}>
      <div className={styles.pageBody}>{children}</div>
      <div className={styles.record}>
        &copy; 1987-{currentYear}
        {I18N.get('layout.whiteLayout.index.layoutWhitelayoutIndex1')}
      </div>
    </div>
  )
}
export default WhiteLayout
