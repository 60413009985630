import { useRequest } from 'ahooks'
import { Options } from 'ahooks/lib/useRequest/src/types'
import allApi from './index'

type IAllApi = typeof allApi

/**
 * 接口调用钩子
 * @param name 接口名称
 * @param options useRequest参数
 * @returns useRequest结果
 */
const useApi = (name: keyof IAllApi, options?: Options<any, any>) => {
  const requestFunc = (params?: any) => allApi[name](params)
  const result = useRequest(requestFunc, {
    manual: true,
    ...options,
  })
  return result
}

export { useApi }
