import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Loading } from '@fruits-chain/react-bailu'
import useUrlState from '@ahooksjs/use-url-state'
import { useSessionStorageState } from 'ahooks'
import usePermissions from '@/stores/permissions'
import SsoLogin from '@/components/sso-login'
import LangSelect from '@/layout/components/lang-select'
import { useI18n } from '@/i18n/context'
import { MenuPath } from '@/router'
import { useApi } from '@/service/api'
import useSsoLogin from '@/components/sso-login/useSsoLogin'
import { disableJumpTenant, jumpByTenant } from '@/utils/user-helper'
import styles from './style.module.less'

const ssoConfig = {
  response_type: 'code',
  client_id: 'fc-boss',
}

const LoginPage: React.FC = () => {
  const { I18N } = useI18n()
  const navigate = useNavigate()
  const [state] = useUrlState()
  const [_, setIsIndependentApp] = useSessionStorageState<boolean | undefined>(
    'is-independent-app',
    {
      defaultValue: false,
    },
  )

  const { clear, token: appToken } = usePermissions()
  const { setState: setPermissionsState } = usePermissions
  const [loading, setLoading] = useState(false)
  const [authorizeHost, setAuthorizeHost] = useState('')

  // 获取用户信息接口
  const { refreshAsync: getUserAllInfo } = useApi('getUserAllInfo')
  // 获取组织列表接口
  const { runAsync: getListOrg } = useApi('getListOrg')
  // 选择租户接口
  const { runAsync: chooseTenant } = useApi('chooseTenant')
  // 选择组织接口
  const { runAsync: chooseOrg } = useApi('chooseOrg')
  // 校验token接口
  const { runAsync: verifyToken } = useApi('verifyToken')
  // 获取登录地址接口（实际上是在ng上写死的接口）
  const { runAsync: getStaticApiJson } = useApi('getStaticApiJson')
  // 获取用户状态信息接口
  const { runAsync: getLoginUserInfoApi } = useApi('getLoginUserInfo')
  // 登录成功后逻辑
  const loginSuccess = token => {
    setPermissionsState({ token })
    jumpJudge()
  }
  const { jumpLogin, loading: loginLoading } = useSsoLogin({
    success: loginSuccess,
    ...ssoConfig,
    authorizeHost,
  })
  const jumpSelectTenant = () => {
    navigate(`/select-tenant`)
  }
  // 跳转判断，当只有一个租户一个组织的时候直接跳转到菜单页,否则进入选择组织页面
  const jumpJudge = async () => {
    setLoading(true)
    try {
      const { data: userInfo } = await getUserAllInfo()

      if (userInfo?.tenantInfos?.length === 1) {
        const { data: orgList } = await getListOrg({
          tenantId: userInfo?.tenantInfos?.[0]?.tenantId,
        })
        if (orgList?.length === 1) {
          // 选择租户
          const { data: tenant } = await chooseTenant({
            tenantId: userInfo?.tenantInfos[0]?.tenantId,
            tenantMode: disableJumpTenant ? 0 : 1,
          })
          // 选择组织
          await chooseOrg({ orgId: orgList[0]?.orgId, token: tenant?.token })
          // 判断要跳转的页面
          jumpByTenant({
            userInfo,
            tenant,
            callback: async () => {
              const { data: userStatusInfo } = await getLoginUserInfoApi()
              window?.opener?.postMessage(
                { token: appToken, userInfo, userStatusInfo },
                '*',
              )
              setPermissionsState({ userStatusInfo, userInfo })
              navigate(MenuPath)
            },
          })
        } else {
          setLoading(false)
          jumpSelectTenant()
        }
      } else {
        setLoading(false)
        jumpSelectTenant()
      }
    } catch {
      setLoading(false)
      jumpSelectTenant()
    }
  }
  /**
   * 进入登录页面校验登录状态
   */
  const onMount = async () => {
    if (state?.isIndependentApp) {
      setIsIndependentApp(true)
    }
    if (state?.isIndependentApp || state?.event === 'logout') {
      loginPageMount()
    } else {
      if (appToken) {
        try {
          const { data } = await verifyToken()
          if (data) {
            loginSuccess(appToken)
          } else {
            loginPageMount()
          }
        } catch {
          loginPageMount()
        }
      } else {
        loginPageMount()
      }
    }
  }
  /**
   * 进入登录页面的逻辑
   */
  const loginPageMount = () => {
    // 清除登录状态
    clear()
    // 获取登录地址
    getStaticApiJson().then(data => {
      setAuthorizeHost(data?.authUrl)
      setPermissionsState({ bossUrl: data?.bossUrl })
    })
  }

  useEffect(() => {
    onMount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 登录返回后直接渲染loading
  if (state?.code || loading || loginLoading)
    return (
      <div className={styles.loading}>
        <Loading />
      </div>
    )

  return (
    <div className={styles.login}>
      <div className={styles.loginBox}>
        <h2 className={styles.title}>
          {I18N.get('pages.login.index.pagesLoginIndex1')}
        </h2>
        <SsoLogin jumpLogin={jumpLogin} />
        <div className={styles.lang}>
          <LangSelect />
        </div>
      </div>
    </div>
  )
}
export default LoginPage
