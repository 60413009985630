import { useEffect } from 'react'
import { useApi } from '@/service/api'
import usePermissions from '@/stores/permissions'

/**
 * init 基础信息
 */
const useInitConfig = () => {
  // 获取登录地址接口（实际上是在ng上写死的接口）
  const { runAsync: getStaticApiJson } = useApi('getStaticApiJson')
  const { bossUrl } = usePermissions()
  useEffect(() => {
    if (!bossUrl) {
      getStaticApiJson().then(data => {
        usePermissions.setState({ bossUrl: data?.bossUrl })
      })
    }
  }, [bossUrl, getStaticApiJson])
}

export default useInitConfig
