export const childAppList = {
  operation: [
    {
      menuJson: '/oss-base-web/menu.json',
      entryUrl: '/oss-base-web/',
    },
    {
      menuJson: '/oss-customs-web/menu.json',
      entryUrl: '/oss-customs-web/',
    },
    {
      menuJson: '/oss-durian-web/menu.json',
      entryUrl: '/oss-durian-web/',
    },
    {
      menuJson: '/oss-finance-web/menu.json',
      entryUrl: '/oss-finance-web/',
    },
    {
      menuJson: '/oss-peach-web/menu.json',
      entryUrl: '/oss-peach-web/',
    },
    {
      menuJson: '/oss-pitaya-web/menu.json',
      entryUrl: '/oss-pitaya-web/',
    },
    {
      menuJson: '/oss-transport-web/menu.json',
      entryUrl: '/oss-transport-web/',
    },
    {
      menuJson: '/oss-user-web/menu.json',
      entryUrl: '/oss-user-web/',
    },
  ],
  financial: [
    {
      menuJson: '/financial_web/menu.json',
      entryUrl: '/financial_web/',
    },
    {
      menuJson: '/financial-accounting_web/menu.json',
      entryUrl: '/financial-accounting_web/',
    },
  ],
}

export const getChildAppKeys = () => {
  let keys = []
  for (const key in childAppList) {
    if (Object.prototype.hasOwnProperty.call(childAppList, key)) {
      keys?.push(key)
    }
  }
  return keys
}

export const hasChildApp = code => {
  return getChildAppKeys()?.includes(code)
}
