import { useMemo } from 'react'
import { useCurrentApp } from '@/layout/useCurrentApp'
import useChildAppStore from './stores/useChildAppStore'

const useCurrentRoute = () => {
  const currentApp = useCurrentApp()
  const { allRoutes } = useChildAppStore()
  const routes = useMemo(() => {
    return allRoutes?.[currentApp?.code] || []
  }, [currentApp?.code, allRoutes])
  return routes
}

export default useCurrentRoute
