import React, { useMemo } from 'react'
import { RouteObject, useRoutes } from 'react-router-dom'

import Page404 from '@/pages/others/error-404'
import usePermissions from '@/stores/permissions'
import LoginPage from '@/pages/login'
import SelectTenant from '@/pages/select-tenant'
import LayoutPage from '@/layout/index'
import MicroAppsMenu from '@/pages/menu/index'
import LoadingPage from '@/components/loading'
import Redirect from '@/pages/redirect'
import HelpCenter from '@/pages/help-center'
import useIsLogin from './useIsLogin'
import useInitConfig from './useInitConfig'

export const MenuPath = '/menu'

const Routes = () => {
  const { apps, baseLoading } = usePermissions()

  // 是否登录，没登录跳转到login页面
  useIsLogin()
  // init 基础信息
  useInitConfig()
  const routes: RouteObject[] = useMemo(() => {
    return [
      {
        path: '/',
        element: <LayoutPage />,
        children: [
          {
            path: 'menu',
            element: <MicroAppsMenu />,
          },
        ],
      },
      ...(apps?.map(v => ({
        path: `/${v?.code}/*`,
        element: <LayoutPage />,
      })) || []),
      {
        path: '/login',
        element: <LoginPage />,
      },
      {
        path: '/select-tenant',
        element: <SelectTenant />,
      },
      {
        path: '/redirect',
        element: <Redirect />,
      },
      { path: '/help-center', element: <HelpCenter /> },
      { path: '*', element: <Page404 /> },
    ]
  }, [apps])
  const routers = useRoutes(routes)

  if (baseLoading && !(apps?.length > 0)) {
    return <LoadingPage />
  }
  return routers
}

export default Routes
