export default {
  /**
   * 确定要退出登录吗？
   */
  layoutComponentsUserinfoIndex1: '确定要退出登录吗？',

  /**
   * 确定
   */
  layoutComponentsUserinfoIndex2: '确定',

  /**
   * 取消
   */
  layoutComponentsUserinfoIndex3: '取消',

  /**
               * 修改密码;
 
               */
  layoutComponentsUserinfoIndex4: '修改密码',

  /**
               * 退出登录;
 
               */
  layoutComponentsUserinfoIndex5: '退出登录',
};
