import { useEffect } from 'react'
import usePermissions from '@/stores/permissions'
import { useApi } from '@/service/api'
/**
 * 聚焦获取用户状态userStatusInfo
 */
const useGetUserStatusInfo = () => {
  const permissionsStore = usePermissions()
  const { setState } = usePermissions
  const { token } = permissionsStore
  // 用户状态信息在窗口聚焦时自动刷新
  useApi('getLoginUserInfo', {
    refreshOnWindowFocus: !!token,
    // 因为自动刷新，成功逻辑写在接口调用
    onSuccess: res => {
      setState({ userStatusInfo: res.data })
    },
  })
}

export default useGetUserStatusInfo
