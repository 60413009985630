import { useDeepCompareEffect } from 'ahooks'
import dayjs from 'dayjs'
import { CSSProperties, useEffect, useState } from 'react'

import { RouteProps } from 'react-router-dom'
import { IconType } from 'antd/lib/notification'
import usePermissions from '@/stores/permissions'
import { request } from '@/utils/fetch'
import { MicroConfigType } from '@/micro.config'
import { useCurrentApp } from '@/layout/useCurrentApp'
import useChildAppStore from './stores/useChildAppStore'
import { childAppList, hasChildApp } from './configData'

export interface CustomRouteConfig extends Omit<RouteProps, 'children'> {
  /** 面包屑配置 */
  // breadcrumb?: BreadcrumbItem[]
  /** 页面信息配置 */
  meta?: {
    /** 系统左侧菜单栏文案（为空或未配置则不会出现在菜单栏） */
    title?: string
    /** 菜单按钮 */
    icon?: IconType
    /** 主内容区域style */
    contentStyle?: CSSProperties
    /** 是否作为菜单项 */
    isMenu?: boolean
  }
  /** 子路由 */
  children?: CustomRouteConfig[]
  /** 主菜单路由排序，自定义顺序 */
  order?: number
  path: string
  authKey?: string
  appMicroConfig?: MicroConfigType
}
/**
 * 获取权限情况下的路由配置
 * @param routes 静态路由配置
 * @param permissions 当前用户的权限列表
 */
export function getRoutesWithPermissions(
  routes: CustomRouteConfig[],
  permissions: string[],
) {
  if (!routes.length) {
    return []
  }
  const result: CustomRouteConfig[] = []
  const hasAuth = (routeWithSubRoute: CustomRouteConfig) => {
    if (
      (routeWithSubRoute?.authKey &&
        permissions?.includes(routeWithSubRoute?.authKey)) ||
      routeWithSubRoute?.path === '*' ||
      !!routeWithSubRoute?.authKey === false
    ) {
      return true
    } else {
      return false
    }
  }
  function routesLoop(routeWithSubRoute: CustomRouteConfig) {
    if (!hasAuth(routeWithSubRoute)) {
      return null
    }
    const temp: CustomRouteConfig[] = []
    if (routeWithSubRoute.children) {
      for (const currentRoute of routeWithSubRoute.children) {
        // 如果当前路由是模糊匹配路由/有权限/不需要权限
        if (hasAuth(currentRoute)) {
          if (currentRoute.children && currentRoute.children.length > 0) {
            const node = routesLoop(currentRoute)
            if (node) {
              temp.push(node)
            }
          } else {
            temp.push(currentRoute)
          }
        }
      }
    }
    // 叶节点有值
    if (temp.length) {
      return {
        ...routeWithSubRoute,
        children: temp,
      }
    }
    // 叶节点无可用值
    return null
  }
  // 遍历一级节点
  for (const route of routes) {
    if (route.children) {
      const temp = routesLoop(route)
      if (temp) {
        result.push(temp)
      }
    } else {
      if (hasAuth(route)) {
        result.push(route)
      }
    }
  }
  return result
}

/**
 * 把从子应用拿到的路由整理格式
 */
export const setRoutesApp = (
  routes: any[],
  entryUrl: string,
  codeKey: string,
): CustomRouteConfig[] => {
  const menus =
    routes?.map(item => {
      const children = setRoutesApp(item?.routes, entryUrl, codeKey)
      const meta = item?.meta?.menu
      const code = item?.path?.split('/')?.[1]
      return {
        ...item,

        path: `/${codeKey}` + item?.path,
        ...(item?.order ? { order: item?.order } : {}),
        ...(meta ? { meta: { ...meta } } : {}),
        // 递归处理
        ...(children?.length > 0 ? { children } : {}),
        appMicroConfig: {
          ...item?.appMicroConfig,
          code,
          entryUrl: entryUrl,
        },
      }
    }) || null
  return menus
}

const useRoutesData = () => {
  const { token } = usePermissions()
  const { allPermissions, allRoutes } = useChildAppStore()
  const { setState } = useChildAppStore
  const [childRoutes, setChildRoutes] = useState<CustomRouteConfig[]>([])
  const currentApp = useCurrentApp()
  useEffect(() => {
    if (token && hasChildApp(currentApp?.code)) {
      const promiseList = []
      let routes: any[] = []
      const appList = childAppList?.[currentApp?.code] || []
      appList?.forEach(v => {
        promiseList?.push({
          ...v,
          request: request({
            url: `${v?.menuJson}?t=${dayjs().valueOf()}`,
            method: 'get',
          }).then(data => {
            routes = [
              ...routes,
              ...(setRoutesApp(data, v?.entryUrl, currentApp?.code) || []),
            ]
            return data
          }),
        })
      })
      Promise.allSettled(promiseList?.map(v => v?.request)).finally(() => {
        setChildRoutes(routes)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, currentApp?.code])

  useEffect(() => {
    if (currentApp?.appId) {
      const queryPermissions = () => {
        //  请求权限接口
        request({
          url: `/auth/listUserPower`,
          method: 'post',
          data: {
            appId: currentApp?.appId,
          },
        }).then(({ data }) => {
          setState({
            allPermissions: {
              ...allPermissions,
              [currentApp?.code]: data?.map(v => v?.powerCode),
            },
          })
        })
      }
      if (token) {
        queryPermissions()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setState, token, currentApp?.appId])

  useDeepCompareEffect(() => {
    const permissions = allPermissions?.[currentApp?.code] || []
    if (childRoutes?.length > 0 && permissions?.length > 0) {
      setState({
        allRoutes: {
          ...(allRoutes || {}),
          [currentApp?.code]: getRoutesWithPermissions(
            childRoutes,
            permissions,
          ),
        },
      })
    }
  }, [childRoutes, allPermissions])
}

export default useRoutesData
