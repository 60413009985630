import type { FC } from 'react'
import React from 'react'
import useUrlState from '@ahooksjs/use-url-state'
import cs from 'classnames'
import { useGetHelpManageTypeQuery } from '@/graphql/gqls/help-center/__generated__/index.generated'
import { defaultParams } from '../../const'
import styles from './index.module.less'
export interface HelpMenusProps {}

const HelpMenus: FC<HelpMenusProps> = () => {
  const { data } = useGetHelpManageTypeQuery()
  const [urlParams, setUrlParams] = useUrlState<{ helpType: string }>()

  return (
    <div className={styles.menu}>
      {data?.getHelpManageType?.map((v, i) => {
        return (
          <div
            key={i}
            className={cs(
              styles.menuItem,
              v?.value === urlParams?.helpType ? styles.active : null,
            )}
            onClick={() => {
              if (v?.label !== urlParams?.helpType) {
                setUrlParams({ ...defaultParams, helpType: v?.value })
              }
            }}>
            {v?.label}
          </div>
        )
      })}
    </div>
  )
}
export default HelpMenus
