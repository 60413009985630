import { useEffect, useState } from 'react'

const CRITICAL_POINT = 750

export const assertsIsMobile = () => (window?.innerWidth ?? 0) < CRITICAL_POINT

// 项目是按照屏幕宽来划分是否是移动端？
export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(assertsIsMobile())

  useEffect(() => {
    const onResize = () => {
      setIsMobile(assertsIsMobile())
    }

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return isMobile
}
