import create from 'zustand'
import _create from 'zustand/vanilla'
import { persist } from 'zustand/middleware'
import { MicroConfigType } from '@/micro.config'

type BossChildAppState = {
  /** apps */
  allChildApps: { [key: string]: MicroConfigType[] }
  /** 当前所有routes */
  allRoutes: { [key: string]: any[] }
  allPermissions: { [key: string]: string[] | null }
}

export const bossAppDataKey = 'boss-child-app'

export const childAppStore = _create<BossChildAppState>(
  persist(
    (set, get) => ({
      allChildApps: null,
      allRoutes: null,
      allPermissions: null,
    }),
    {
      name: bossAppDataKey,
    },
  ),
)

const useChildAppStore = create(childAppStore)
export default useChildAppStore
