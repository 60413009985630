export default {
  langs: [
    { label: '中文', value: 'zh-CN' },
    { label: 'English', value: 'en-US' },
    { label: 'ภาษาไทย', value: 'th-TH' },
    { label: 'ViệtName', value: 'vi-VN' },
  ],
  layout: {
    components: {
      userInfo: {
        index: {
          layoutComponentsUserinfoIndex1:
            'Are you sure to sign out the system?',
          layoutComponentsUserinfoIndex2: 'Confirm',
          layoutComponentsUserinfoIndex3: 'Cancel',
          layoutComponentsUserinfoIndex4: 'Change Password',
          layoutComponentsUserinfoIndex5: 'sign out',
        },
      },
    },
    index: { layoutIndex1: 'HongJiu StarBridge', home: 'Homepage' },
    whiteLayout: {
      index: {
        layoutWhitelayoutIndex1:
          '渝ICP备19002690号 - 7',
      },
    },
  },
  pages: {
    login: {
      index: {
        pagesLoginIndex1:
          'Welcome to Integrated Management Platform of HongJiu Fruit',
        login: 'Login',
      },
    },
    menu: { index: { pagesMenuIndex1: 'Welcome to HongJiu StarBridge' } },
    'select-tenant': {
      index: {
        pagesSelecttenantIndex1: 'Select tenant/organization',
        pagesSelecttenantIndex2: 'Organization',
        pagesSelecttenantIndex3: 'Choose your organization',
        pagesSelecttenantIndex4: 'Confirm',
        pagesSelecttenantIndex5: 'Sign In Instead',
        pagesSelecttenantIndex6: 'Tenantry',
        pagesSelecttenantIndex7: 'Please select the type of tenant',
      },
    },
  },
  components: {
    business: {
      selectAppModal: {
        index: {
          components_businessSelectappmodalIndex1: 'Confirm',
          components_businessSelectappmodalIndex2: 'Switch system',
          components_businessSelectappmodalIndex3:
            'Please select the type of tenant',
          components_businessSelectappmodalIndex4: 'Choose your organization',
          components_businessSelectappmodalIndex5: 'Select system',
          components_businessSelectappmodalIndex6:
            'No system permissions under this organization',
        },
      },
      pwdModification: {
        index: {
          components_businessPwdmodificationIndex1: 'Confirm',
          components_businessPwdmodificationIndex2: 'Change Password',
          components_businessPwdmodificationIndex3: 'Update successfully',
          components_businessPwdmodificationIndex4: 'Old password',
          components_businessPwdmodificationIndex5: 'Password',
          components_businessPwdmodificationIndex6: 'Password',
          components_businessPwdmodificationIndex7: 'New Password',
          components_businessPwdmodificationIndex8: 'Enter the New Password',
          components_businessPwdmodificationIndex9:
            'New password cannot be same with old',
          components_businessPwdmodificationIndex10: 'Enter the New Password',
          components_businessPwdmodificationIndex11: 'Confirm Password',
          components_businessPwdmodificationIndex12: 'Please confirm password',
          components_businessPwdmodificationIndex13: 'Entered passwords differ',
          components_businessPwdmodificationIndex14: 'Please confirm password',
        },
      },
    },
  },
  utils: {
    verify: {
      index: {
        utilsVerifyIndex1: 'Password must be 8 to 20 digits long',
        utilsVerifyIndex2: 'Password needs to contain numbers',
        utilsVerifyIndex3: 'Password needs to contain capital letters',
        utilsVerifyIndex4: 'Password needs to contain lowercase letters',
        utilsVerifyIndex5: 'Special characters are required',
        utilsVerifyIndex6: 'No Space Please',
        utilsVerifyIndex7: 'Cannot contain special characters',
      },
    },
    fetch: {
      index: {
        utilsFetchIndex1: 'Access Denied',
        utilsFetchIndex2: 'Your login has expired, please log in again',
        utilsFetchIndex3: 'Request timed out, please try again later',
        utilsFetchIndex4:
          'Report! Something went wrong with the server, please try again later...',
        utilsFetchIndex5:
          'There is a problem with your network, please check your network and try again',
      },
    },
  },
}
