import React, { useMemo } from 'react'
import { GlobalOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import { useI18n } from '@/i18n/context'
import styles from './style.module.less'

const LangSelect: React.FC = () => {
  const { setLangTriggerRender, I18N } = useI18n()
  const langs: any = I18N.get('langs')
  const currentLang = useMemo(() => {
    return langs?.find(v => v?.value === I18N.currentLang)
  }, [I18N.currentLang, langs])
  const menuJSX = (
    <Menu selectedKeys={[currentLang?.value]}>
      {langs?.map(v => {
        return (
          <Menu.Item
            key={v?.value}
            onClick={() => {
              setLangTriggerRender(v?.value)
            }}>
            {v?.label}
          </Menu.Item>
        )
      })}
    </Menu>
  )

  return (
    <Dropdown overlay={menuJSX} trigger={['click']}>
      <div className={styles.langSelect}>
        <GlobalOutlined />
        <span className={styles.langText}>{currentLang?.label}</span>
      </div>
    </Dropdown>
  )
}

export default LangSelect
