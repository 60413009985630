import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react'
import type { ModalProps } from 'antd'
import { message, Modal, Form, Input } from 'antd'
import type { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import { checkPassword } from '@/utils/verify/index'
import { useI18n } from '@/i18n/context'
import { useApi } from '@/service/api'
import usePermissions from '@/stores/permissions'
export interface Handles {
  /**
   * 打开弹框
   */
  open: () => void
  /**
   * 关闭弹框
   */
  close: () => void
}
interface Values {
  /** 旧密码 */
  oldPassword: string
  /** 新密码 */
  newPassword: string
  /** 重复密码 */
  reNewPwd: string
}
interface Props extends Omit<ModalProps, 'visible' | 'onOk' | 'okButtonProps'> {
  /**
   * 校验成功事件
   * @param values 表单值
   * @returns 修改成功后的回调
   */
  onOk?: () => void
  /**
   * 校验失败事件
   * @param error 表单校验失败的错误信息
   */
  onFail?: (error: ValidateErrorEntity<Values>) => void
}
/** 重置密码弹框组件 */
const ResetPassword = forwardRef<Handles, Props>(
  ({ onCancel, onOk, onFail, afterClose, ...modalProps }, ref) => {
    const { userInfo } = usePermissions()
    const { I18N } = useI18n()
    const [visible, setVisible] = useState(false)
    const { loading, runAsync: updatePassword } = useApi('updatePassword')
    const [form] = Form.useForm<Values>()
    /** 打开弹框 */
    const open = useCallback(() => {
      setVisible(true)
    }, [])
    /** 关闭弹框 */
    const close = useCallback(() => {
      setVisible(false)
    }, [])
    /** 重置组件 */
    const clear = useCallback(() => {
      form.resetFields()
    }, [form])
    /** 对外暴露接口 */
    useImperativeHandle(ref, () => ({
      open,
      close,
    }))

    return (
      <Modal
        okText={I18N.get(
          'components.business.pwdModification.index.components_businessPwdmodificationIndex1',
        )}
        title={I18N.get(
          'components.business.pwdModification.index.components_businessPwdmodificationIndex2',
        )}
        visible={visible}
        okButtonProps={{ loading }}
        onCancel={evt => {
          if (userInfo?.needUpdatePassword) {
            return false
          } else {
            close()
            onCancel?.(evt)
          }
        }}
        afterClose={() => {
          clear()
          afterClose?.()
        }}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              updatePassword({
                newPassword: values?.newPassword,
                oldPassword: values?.oldPassword,
              }).then(({ data }) => {
                if (data) {
                  // 修改成功后续可以关闭了
                  if (userInfo?.needUpdatePassword) {
                    usePermissions.setState({
                      userInfo: { ...userInfo, needUpdatePassword: false },
                    })
                  }
                  message.success(
                    I18N.get(
                      'components.business.pwdModification.index.components_businessPwdmodificationIndex3',
                    ),
                  )
                  onOk?.()
                  close()
                }
              })
            })
            .catch(err => {
              onFail?.(err)
            })
        }}
        {...modalProps}
        maskClosable={!userInfo?.needUpdatePassword}
        cancelText={false}
        cancelButtonProps={{
          style: userInfo?.needUpdatePassword ? { display: 'none' } : null,
        }}
        closable={!userInfo?.needUpdatePassword}>
        <Form layout="vertical" form={form}>
          <Form.Item
            label={I18N.get(
              'components.business.pwdModification.index.components_businessPwdmodificationIndex4',
            )}
            name="oldPassword"
            rules={[
              {
                required: true,
                message: I18N.get(
                  'components.business.pwdModification.index.components_businessPwdmodificationIndex5',
                ),
              },
            ]}>
            <Input.Password
              placeholder={I18N.get(
                'components.business.pwdModification.index.components_businessPwdmodificationIndex6',
              )}
            />
          </Form.Item>
          <Form.Item
            label={I18N.get(
              'components.business.pwdModification.index.components_businessPwdmodificationIndex7',
            )}
            validateFirst
            dependencies={['oldPassword']}
            name="newPassword"
            rules={[
              {
                required: true,
                message: I18N.get(
                  'components.business.pwdModification.index.components_businessPwdmodificationIndex8',
                ),
              },
              ({ getFieldValue }) => {
                return {
                  validator(_, value) {
                    if (getFieldValue('oldPassword') !== value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error(
                        I18N.get(
                          'components.business.pwdModification.index.components_businessPwdmodificationIndex9',
                        ),
                      ),
                    )
                  },
                }
              },
              {
                validator(_, value) {
                  const errors = checkPassword(value)
                  if (errors.length > 0) {
                    return Promise.reject(new Error(errors[0]))
                  }
                  return Promise.resolve()
                },
              },
            ]}>
            <Input.Password
              placeholder={I18N.get(
                'components.business.pwdModification.index.components_businessPwdmodificationIndex10',
              )}
            />
          </Form.Item>
          <Form.Item
            label={I18N.get(
              'components.business.pwdModification.index.components_businessPwdmodificationIndex11',
            )}
            name="reNewPwd"
            dependencies={['newPassword']}
            validateFirst
            rules={[
              {
                required: true,
                message: I18N.get(
                  'components.business.pwdModification.index.components_businessPwdmodificationIndex12',
                ),
              },
              ({ getFieldValue }) => {
                return {
                  validator(_, value) {
                    if (getFieldValue('newPassword') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error(
                        I18N.get(
                          'components.business.pwdModification.index.components_businessPwdmodificationIndex13',
                        ),
                      ),
                    )
                  },
                }
              },
            ]}>
            <Input.Password
              placeholder={I18N.get(
                'components.business.pwdModification.index.components_businessPwdmodificationIndex14',
              )}
            />
          </Form.Item>
        </Form>
      </Modal>
    )
  },
)
export default ResetPassword
