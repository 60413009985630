import I18N from '@/i18n'

/** 密码验证 */
export function checkPassword(password: string) {
  const errors: string[] = []
  if (password === null || password.length < 8 || password.length > 20) {
    errors.push(I18N.get('utils.verify.index.utilsVerifyIndex1'))
    return errors
  }
  if (!/.*[0-9]{1,}.*/.test(password)) {
    errors.push(I18N.get('utils.verify.index.utilsVerifyIndex2'))
    return errors
  }
  if (!/.*[A-Z]{1,}.*/.test(password)) {
    errors.push(I18N.get('utils.verify.index.utilsVerifyIndex3'))
    return errors
  }
  if (!/.*[a-z]{1,}.*/.test(password)) {
    errors.push(I18N.get('utils.verify.index.utilsVerifyIndex4'))
    return errors
  }
  if (!/^[A-Za-z0-9]+$/.test(password)) {
    errors.push(I18N.get('utils.verify.index.utilsVerifyIndex7'))
    return errors
  }

  return errors
}
