import create from 'zustand'
import _create from 'zustand/vanilla'
import { persist } from 'zustand/middleware'
import { MicroConfigType } from '@/micro.config'
import { LangEnum } from '@/i18n/typing'
import { getLocalStorage } from '@/utils/storage'
import { defaultLang } from '@/i18n'

export interface UserInfoType {
  id: number
  userName: string
  phone: string
  userAccount: string

  status: number
  passwordFirstLogin: any
  needUpdatePassword: boolean
  lastLoginTenant: number
  lastLoginOrg: number
  tenantInfos: {
    tenantId: 1
    tenantName: string
    tfn: string
    nameLocale: string
    urlPrefix?: string
  }[]
  appInfos: any[]
  roleInfos: any[]
  orgInfos: any[]
  [key: string]: any
}

type PermissionState = {
  /** apps */
  apps: MicroConfigType[]
  /** 当前用户状态，包含当前选择的租户，组织等 */
  userStatusInfo: any
  /** 登录的token */
  token: string
  /** 基础boss的url */
  bossUrl: string
  /** 刷新token的时间 */
  refreshTokenTime: number
  /** 用户信息 */
  userInfo: any
  /** 当前lang,仅用于向子应用传递 */
  lang: LangEnum
  /** base loading: 在加载app列表的时候的loading */
  baseLoading: boolean
  /** apps loading: 加载app内容的时候会进入loading */
  appLoading: boolean
  /** loginModalIsShown: 未登录弹框是否已经弹框，防止多次弹框 */
  loginModalIsShown: boolean

  clear: () => void
}

export const permissionKey = 'boss-permissions'

export const permissionStore = _create<PermissionState>(
  persist(
    (set, get) => ({
      apps: [],
      userStatusInfo: null,
      lang: getLocalStorage('currentLang') || defaultLang,
      token: null,
      refreshTokenTime: null,
      userInfo: null,
      bossUrl: '',
      baseLoading: false,
      appLoading: false,
      loginModalIsShown: false,
      clear: () => {
        set({
          token: null,
          refreshTokenTime: null,
          userStatusInfo: null,
          userInfo: null,
        })
      },
    }),
    {
      name: permissionKey,
      blacklist: ['loginModalIsShown'],
    },
  ),
)

const usePermissions = create(permissionStore)
export default usePermissions
