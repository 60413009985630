export default {
  /**
   * 确定
   */
  components_businessSelectappmodalIndex1: '确定',
  /**
   * 切换系统
   */
  components_businessSelectappmodalIndex2: '切换系统',
  /**
   * 选择租户
   */
  components_businessSelectappmodalIndex3: '选择租户',
  /**
   * 选择组织
   */
  components_businessSelectappmodalIndex4: '选择组织',
  /**
   * 选择系统
   */
  components_businessSelectappmodalIndex5: '选择系统',
  /**
   * 选择系统
   */
  components_businessSelectappmodalIndex6: '该组织下无系统权限~',
}
